import React, { Fragment } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import moment from "moment";
import { Typography, Icon, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import WidgetContainer from "./widgetContainer";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { CustomFormattedMessage } from "asmi-ui-web";
import StartStop from "./startStop";
import {
  fetchActiveTimer,
  setTimerState,
  startTimer,
  stopTimer,
  toggleTimerPopup
} from "../../actions/time";
import { connect } from "react-redux";


const styles = theme => ({
  icon: {
    display: "block",
    cursor: "pointer",
    "&:first-child": {
      marginBottom: 7,
      fontSize: "1.75rem"
    }
  },
  popover: {
    padding: 20,
    width: "350px"
  },
  title: {
    fontWeight: 700
  },
  select: {
    width: "100%"
  },
  grid: {
    padding: "10px 10px 10px 0"
  },
  rightAlign: {
    display: "flex",
    justifyContent: "flex-end"
  },
  cursor: {
    cursor: "pointer"
  },
  autocomplete: {
    paddingLeft: "10px",
    paddingTop: "3px !important",
    paddingBottom: "3px !important"
  }
});

// make it a container
// start stop button should be a separate component which accepts some props for opening, closing and update

class TwitterTimeline extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showFeed: false,
      refreshNum: 0,
      refreshTimeout: null
    }
  }

  onLoad = (node) => {
    const media = node.contentDocument.querySelectorAll("div.timeline-Tweet-media");
    media.forEach(mediaItem => {
      mediaItem.style = "display: none";
    });
    const texts = node.contentDocument.querySelectorAll("p.timeline-Tweet-text");
    texts.forEach(text => {
      text.style.whiteSpace = "normal";
    });
    const authors = node.contentDocument.querySelectorAll("div.timeline-Tweet-author");
    authors.forEach(author => {
      author.style.paddingLeft = "0px";
    });
    const authorNames = node.contentDocument.querySelectorAll("span.TweetAuthor-name");
    authorNames.forEach(author => {
      author.style.opacity = 0.7;
    });
    const avatars = node.contentDocument.querySelectorAll("a.TweetAuthor-avatar");
    avatars.forEach(avatar => {
      avatar.style.display = "none";
    });

    this.setState({ showFeed: true });

    setTimeout(() => {
      clearTimeout(this.state.refreshTimeout);
      this.setState({
        refreshNum: this.state.refreshNum + 1
      });
    }, 2 * 60 * 60 * 1000);
  }

  render() {
    const {
      classes,
      activeEntry,
      stopTimer,
      startTimer,
      toggleTimerPopup
    } = this.props;

    return (
      <WidgetContainer>
        <div style={{ maxWidth: '330px' }}>
          <Typography variant="subtitle1">
            <CustomFormattedMessage
              textTransform="capitalize"
              id="widgets.twitter.title"
            />
          </Typography>
          <div key={this.state.refreshNum} style={{ opacity: this.state.showFeed ? 0.85 : 0 }}>
            <TwitterTimelineEmbed sourceType="url" url="https://twitter.com/asmihq/timelines/1285085402806669313" noHeader={true} noFooter={true} noBorders={true} transparent={true} options={{ tweetLimit: 1 }} onLoad={this.onLoad} />
          </div>
        </div>
      </WidgetContainer>
    );
  }
}

export default TwitterTimeline;
