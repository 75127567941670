export default {
  primary: {
    main: "#1EE3CF", // bright green

    light: "#7adbde",
    dark: "#3e9395",
    contrastText: "#fff" // drives the button primary colors
  },
  secondary: {
    // drives the button secondary or icon colors
    main: "#4586FF", // blue
    background: "rgba(50,66,75, 0.5)",
    light: "#a5a5a6",
    dark: "#f0f0f1",

    contrastText: "#fff", // drives the button secondary colors
    lightWhite: "#FAFAFA"
  },
  text: {
    primary: "rgba(50,66,74, 0.65)",
    secondary: "#4f5f63",
    dark: "#19262a",
    contrastText: "#fff" // drives the side bar colors requiring #24373d as main
  },
  logoColor: "#32424A", //rgb(50,66,74)
  divider: "rgba(50,66,74, 0.08)",
  imageBorder: "rgba(29,222,202, 0.50)",
  lightRed: "#FF888B",
  darkRed: "#F96657",
  grey: "#FBFBFB",
  greyDark: "#848d92",
  greyLight: "#F5F5F5",
  borderColor: "rgba(255,255,255,0.6)"
};
