import {
    NAME,
    ADDRESS,
    PAYMENTMETHOD,
    NOTES,
    FOOTER,
    LOGO,
    VAT,
    SHIPPING,

} from "../actions/settingInvoices";




const initState ={
    name:{},
    address:{},
    paymentMethod:{},
    notes:{},
    footer:{},
    logoToggle: false,
    vatToggle: false,
    shippingToggle:false,
};

export default (state = initState, action) => {
    switch (action.type) {
        case NAME:
            console.log(action.value,'reducer')
            return { ...state,
                name:action.value};
        case ADDRESS:
            console.log(action.value,'reducer')
            return { ...state,
                address:action.value};
        case PAYMENTMETHOD:
            console.log(action.value,'reducer')
            return {
                ...state,
                paymentMethod:action.value}; 
        case NOTES:
            console.log(action.value,'reducer')
            return { ...state,
                notes:action.value};  
        case FOOTER:
            console.log(action.value,'reducer')
            return { ...state,
                footer:action.value}; 
        case LOGO:
            console.log(action.value,'reducer')
            return {
                ...state,logoToggle:action.value
            }
        case VAT:
            console.log(action.value,'reducer')
            return { ...state,
                vatToggle:action.value};  
        case SHIPPING:
            console.log(action.value,'reducer')
            return { ...state,
                shippingToggle:action.value};

        default:
            return state;
    }
};