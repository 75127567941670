import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FilledInput from '@material-ui/core/FilledInput'
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { Formik, FieldArray } from 'formik';
import { toast } from "react-toastify";
import {
  fetchClientSheet
} from "actions/clients";
import {
  toggleProjectFormDialog,
  editProject
} from "actions/projects";
import ProjectClientFormDialog from "components/projects/forms/ProjectClientFormDialog";
import LoadingSpinner from "components/shared/loadingSpinner";
import * as apiClient from 'asmi-api-client-js';

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(3)
  },
  tableRowCell: {
    border: "none",
    color: "rgba(50,66,74, 0.65)",
    fontSize: "1rem",
    padding: "5px 0px"
  },
  loadingSpinner: {
    top: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    borderRadius: "4px",
    backgroundColor: "#f7f8f8"
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomInput = React.forwardRef((props, ref) => {
  return <TextField
    ref={ref}
    label={props.placeholder}
    name={props.name}
    placeholder={props.placeholder}
    value={props.value}
    onClick={props.onClick}
    type="text"
    helperText={""}
    variant="filled"
    fullWidth
  />
});

const validationSchema = Yup.object().shape({
  project: Yup.object().shape({
    name: Yup.string().min(2, 'Too short!').max(50, 'Too long!').required('Name is required'),
    clientId: Yup.string().required('Client is required')
  }),
  taskCategories: Yup.array().min(1, 'Add task categories').required('Required')
});

const ProjectForm = (props) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const {
    project,
    activeProject,
    projectFormDialogIsOpen,
    toggleProjectFormDialog,
    editProject,
    fetchClientSheet,
    clients
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  //category
  const [initialValue, setInitialValue] = useState({
    project: {
      name: "",
      clientId: "",
      clientName: "",
      startDate: null,
      endDate: null,
      notes: "",
      billingType: "",
      projectFeesBudget: "",
      projectHoursBudget: "",
      perTaskBudgetType: "",
      perMemberBudgetType: "",
      resetBudgetFreq: "none"
    },
    taskCategories: []
  });
  const [anchorElCategory, setAnchorElCategory] = useState(null);
  const [categoryList, setCategoryList] = useState([])
  const [initialProjectCategoryList, setInitialProjectCategoryList] = useState([]);
  const [projectClientFormDialogIsOpen, setProjectClientFormDialogIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      editProject(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (activeProject) {
        setIsLoading(true);
        try {
          const [taskCategories, res, clients] = await Promise.all([
            apiClient.getAllTaskCategories(props.user.id, props.account.id),
            apiClient.getProject(activeProject.userId, activeProject.projectId),
            apiClient.getAllClients(activeProject.userId)
          ]);
          if (taskCategories && res && clients && isMounted.current) {
            //remove all projectCategory from taskCatgory
            const remainingTaskCategoryList = taskCategories.sort((a, b) => a.title > b.title ? 1 : (a.title < b.title ? -1 : 0)).filter(function (taskItem) {
              let isIncluded = false;
              res.taskCategories.forEach((projectItem) => {
                if (projectItem.taskCategoryId === taskItem.id) {
                  isIncluded = true;
                }
              })
              //if isIncluded return false we don't need that taskItem
              return !isIncluded
            });
            fetchClientSheet(clients);
            setCategoryList(remainingTaskCategoryList);
            setInitialProjectCategoryList(res.taskCategories.map(item => ({ id: item.id, taskCategoryId: item.taskCategoryId })));
            setInitialValue({
              project: {
                name: res.project.name ? res.project.name : "",
                clientId: res.project.clientId ? res.project.clientId : "",
                clientName: res.project.clientName ? res.project.clientName : "",
                startDate: res.project.startDate ? new Date(res.project.startDate) : null,
                endDate: res.project.endDate ? new Date(res.project.endDate) : null,
                notes: res.project.notes ? res.project.notes : "",
                billingType: res.project.billingType ? res.project.billingType : "",
                projectFeesBudget: res.project.projectFeesBudget ? res.project.projectFeesBudget : "",
                projectHoursBudget: res.project.projectHoursBudget ? res.project.projectHoursBudget : "",
                perTaskBudgetType: res.project.perTaskBudgetType ? res.project.perTaskBudgetType : "",
                perMemberBudgetType: res.project.perMemberBudgetType ? res.project.perMemberBudgetType : "",
                resetBudgetFreq: res.project.resetBudgetFreq ? res.project.resetBudgetFreq : ""
              },
              taskCategories: res.taskCategories
            });
            setIsLoading(false);
          }
        } catch (error) {
          toast(error.message);
          setIsLoading(false);
        }

      } else {
        setIsLoading(true);
        try {
          const [taskCategories, clients] = await Promise.all([
            apiClient.getAllTaskCategories(props.user.id, props.account.id),
            apiClient.getAllClients(props.user.id)
          ]);
          if (taskCategories && clients) {
            if (isMounted.current) {
              fetchClientSheet(clients);
              setCategoryList(taskCategories.sort((a, b) => a.title > b.title ? 1 : (a.title < b.title ? -1 : 0)));
              setIsLoading(false);
            }
          }
        } catch (e) {
          toast(e.message);
          setIsLoading(false);
        }
      }
    })();
  }, [activeProject, editProject, props.account.id, props.user.id]);

  const onCloseHandler = useCallback(() => {
    toggleProjectFormDialog(false);
  }, [])


  const toggleProjectClientFormDialogIsOpen = useCallback(() => {
    setProjectClientFormDialogIsOpen(prevState => !prevState);
  }, [])

  const onAfterClientCreation = useCallback((formik, _client) => {
    fetchClientSheet([...clients, _client]);
    formik.setFieldValue("project.clientName", _client.name);
    formik.setFieldValue("project.clientId", _client.id);
    setProjectClientFormDialogIsOpen(false);
  }, [clients])


  return (
    <Dialog
      open={projectFormDialogIsOpen}
      onClose={onCloseHandler}
      fullWidth
      maxWidth="sm"
      TransitionComponent={Transition}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={values => {
          setIsLoading(true);
          if (activeProject) {
            //filter initialProjectCategoryList from final cateroryList to get List of removed projectCategoeryList
            const removedProjectCategoryList = initialProjectCategoryList.filter(initialProjectCategory => {
              let isIncluded = false;
              values.taskCategories.forEach((projectItem) => {
                if (projectItem.taskCategoryId === initialProjectCategory.taskCategoryId) {
                  isIncluded = true;
                }
              })
              //if isIncluded return false we don't need that Id
              return !isIncluded
            });
            //return only projectId from the removedProjectCategoryList
            const removedProjectCategoryListId = removedProjectCategoryList.map(item => item.id);
            apiClient.updateProject(activeProject.userId, activeProject.projectId, values.project, values.taskCategories, removedProjectCategoryListId, [], [])
              .then(res => {
                if (isMounted.current) {
                  setIsLoading(false);
                  toggleProjectFormDialog(false);
                }
              })
              .catch(e => {
                if (isMounted.current) {
                  toast(e.message);
                  console.log(e.message);
                  setIsLoading(false);
                }
              })
          } else {
            //create project
            apiClient.createProject(props.user.id, props.account.id, values.project, values.taskCategories, [])
              .then(res => {
                if (isMounted.current) {
                  setIsLoading(false);
                  toggleProjectFormDialog(false);
                }
              })
              .catch(e => {
                if (isMounted.current) {
                  toast(e.message);
                  console.log(e.message);
                  setIsLoading(false);
                }
              })
          }
        }}>

        {(formik) => (
          <>
            <LoadingSpinner isLoading={isLoading} radius={true} light={true} />
            <div className={classes.headerTitle} >
              <Typography variant="h1">{activeProject ? `Update project ${activeProject.name}` : "Add Project"}</Typography>
              <IconButton onClick={onCloseHandler}>
                <CancelIcon color="secondary" />
              </IconButton>
            </div>
            <Divider />
            <DialogContent>
              <form autoComplete="off" >
                <Grid container spacing={2} >
                  {/* <Grid item xs={12}>
                    <Typography variant="subtitle1">Basics</Typography>
                  </Grid> */}

                  <Grid item xs={6}>
                    <TextField
                      label="Project name"
                      name="name"
                      type="text"
                      placeholder="Project name"
                      {...formik.getFieldProps("project.name")}
                      variant="filled"
                      helperText={formik.touched.project && formik.errors.project && formik.touched.project.name && formik.errors.project.name}
                      error={formik.touched.project && formik.errors.project && formik.touched.project.name && formik.errors.project.name ? true : false}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} container spacing={1} alignItems="center">
                    <Grid item style={{ flexGrow: 1 }}>
                      <Autocomplete
                        options={clients}
                        getOptionLabel={option => option.name ? option.name : ""}
                        // defaultValue={formik.values.project.clientName}
                        disableClearable={true}
                        loading={isLoading}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={{ name: formik.values.project.clientName, id: formik.values.project.clientId }}
                        onChange={(event, value) => {
                          formik.setFieldValue("project.clientName", value.name);
                          formik.setFieldValue("project.clientId", value.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={formik.touched.project && formik.errors.project && formik.touched.project.clientId && formik.errors.project.clientId}
                            error={formik.touched.project && formik.errors.project && formik.touched.project.clientId && formik.errors.project.clientId ? true : false}

                            name="client"
                            label="Client name"
                            placeholder="Client name"
                            variant="filled" />
                        )}
                        fullWidth
                        openOnFocus
                      />
                    </Grid>
                    <Grid item>
                      <IconButton size="small" onClick={toggleProjectClientFormDialogIsOpen}>
                        <AddCircleIcon color="secondary" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <DatePicker
                      placeholderText="Start date"
                      name="start-date"
                      dateFormat="dd, MMMM yyyy"
                      selected={formik.values.project.startDate}
                      onChange={date => {
                        if (formik.values.project.endDate === null) {
                          formik.setFieldValue("project.startDate", date)
                        } else {
                          if (date <= formik.values.project.endDate) {
                            formik.setFieldValue("project.startDate", date)
                          } else {
                            toast("Start date can not be greater than end date")
                          }
                        }
                      }}
                      customInput={<CustomInput />}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Button size="small" variant="text" onClick={() => formik.setFieldValue("project.startDate", null)}>Reset</Button>
                      </div>
                    </DatePicker>
                  </Grid>

                  <Grid item xs={6}>
                    <DatePicker
                      placeholderText="End date"
                      name="end-date"
                      dateFormat="dd, MMMM yyyy"
                      selected={formik.values.project.endDate}
                      onChange={date => {
                        if (formik.values.project.startDate === null) {
                          formik.setFieldValue("project.endDate", date);
                        } else {
                          if (date >= formik.values.project.startDate) {
                            formik.setFieldValue("project.endDate", date);
                          } else {
                            toast("End date can not be less then start date");
                          }
                        }
                      }}
                      customInput={<CustomInput />}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Button size="small" variant="text" onClick={() => formik.setFieldValue("project.endDate", null)}>Reset</Button>
                      </div>
                    </DatePicker>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Notes"
                      name="notes"
                      multiline
                      placeholder="Notes"
                      {...formik.getFieldProps("project.notes")}
                      helperText={""}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>


                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Billing</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Billing type"
                      name="billingType"
                      select
                      placeholder="Billing type"
                      {...formik.getFieldProps("project.billingType")}
                      helperText={""}
                      variant="filled"
                      fullWidth
                    >
                      <MenuItem value="none">No billing</MenuItem>
                      <MenuItem value="f">Fixed</MenuItem>
                      <MenuItem value="f-t">Fixed, billed by task</MenuItem>
                      <MenuItem value="f-p">Fixed, billed by person</MenuItem>
                      <MenuItem value="tm">Time &amp; money</MenuItem>
                      <MenuItem value="tm-t">Time &amp; money, billed by task</MenuItem>
                      <MenuItem value="tm-p">Time &amp; money, billed by person</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Budget</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Project fees budget"
                      name="projectFeesBudget"
                      type="number"
                      placeholder="Project fees budget"
                      value={formik.values.project.projectFeesBudget}
                      onChange={(e) => {
                        formik.setFieldValue("project.projectFeesBudget", e.target.value);
                        const feesBudget = e.target.value;
                        const hoursBudget = formik.values.project.projectHoursBudget;
                        if (formik.values.project.perTaskBudgetType === "fees") {
                          formik.values.taskCategories.forEach((row, index) => {
                            if (feesBudget > 0 && hoursBudget > 0) {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].hoursBudget`, feesBudget / hoursBudget);
                              }
                            } else {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].hoursBudget`, "");
                              }
                            }
                          })
                        }
                        if (formik.values.project.perTaskBudgetType === "hours") {
                          formik.values.taskCategories.forEach((row, index) => {
                            if (feesBudget > 0 && hoursBudget > 0) {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].feesBudget`, feesBudget / hoursBudget);
                              }
                            } else {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].feesBudget`, "");
                              }
                            }
                          })
                        }
                      }}
                      helperText={""}
                      variant="filled"
                      fullWidth
                      disabled={(formik.values.project.billingType === "none") || (formik.values.project.billingType === "")}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Project hours budget"
                      name="projectHoursBudget"
                      type="number"
                      placeholder="Project hours budget"
                      value={formik.values.project.projectHoursBudget}
                      onChange={(e) => {
                        formik.setFieldValue("project.projectHoursBudget", e.target.value);
                        const feesBudget = formik.values.project.projectFeesBudget;
                        const hoursBudget = e.target.value;
                        if (formik.values.project.perTaskBudgetType === "fees") {
                          formik.values.taskCategories.forEach((row, index) => {
                            if (feesBudget > 0 && hoursBudget > 0) {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].hoursBudget`, feesBudget / hoursBudget);
                              }
                            } else {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].hoursBudget`, "");
                              }
                            }
                          })
                        }
                        if (formik.values.project.perTaskBudgetType === "hours") {
                          formik.values.taskCategories.forEach((row, index) => {
                            if (feesBudget > 0 && hoursBudget > 0) {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].feesBudget`, feesBudget / hoursBudget);
                              }
                            } else {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].feesBudget`, "");
                              }
                            }
                          })
                        }
                      }}
                      helperText={""}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>


                  <Grid item xs={6}>
                    <TextField
                      label="Per task budget type"
                      name="perTaskBudgetType"
                      select
                      placeholder="Per task budget"
                      value={formik.values.project.perTaskBudgetType}
                      onChange={(e) => {
                        formik.setFieldValue("project.perTaskBudgetType", e.target.value);
                        const feesBudget = formik.values.project.projectFeesBudget
                        const hoursBudget = formik.values.project.projectHoursBudget
                        if (e.target.value === "fees") {
                          formik.values.taskCategories.forEach((row, index) => {
                            if (feesBudget > 0 && hoursBudget > 0) {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].hoursBudget`, feesBudget / hoursBudget);
                              }
                            } else {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].hoursBudget`, "");
                              }
                            }
                          })
                        }
                        if (e.target.value === "hours") {
                          formik.values.taskCategories.forEach((row, index) => {
                            if (feesBudget > 0 && hoursBudget > 0) {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].feesBudget`, feesBudget / hoursBudget);
                              }
                            } else {
                              if (formik.values.taskCategories[index].isBillable) {
                                formik.setFieldValue(`taskCategories[${index}].feesBudget`, "");
                              }
                            }
                          })
                        }
                      }}
                      helperText={""}
                      variant="filled"
                      fullWidth
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="fees">Fees</MenuItem>
                      <MenuItem value="hours">Hours</MenuItem>
                    </TextField>
                  </Grid>

                  {/* <Grid item xs={6}>
                    <TextField
                      label="Per team member budget"
                      name="perMemberBudgetType"
                      select
                      placeholder="Per team member budge"
                      {...formik.getFieldProps("project.perMemberBudgetType")}
                      helperText={""}
                      variant="filled"
                      fullWidth
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="fees">Fees</MenuItem>
                      <MenuItem value="hours">Hours</MenuItem>
                    </TextField>
                  </Grid> */}

                  <Grid item xs={12}>
                    <Switch
                      name="resetBudgetFreq"
                      checked={Boolean(formik.values.project.resetBudgetFreq === "monthly")}
                      onChange={(e) => formik.setFieldValue("project.resetBudgetFreq", e.target.checked ? "monthly" : "none")}
                    />
                    <span className="MuiFormHelperText-root MuiFormHelperText-contained" style={{ margin: "0px" }}>Budget resets every month</span>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Task Categories</Typography>
                  </Grid>

                  {formik.values.taskCategories.length > 0 ? (
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table padding="none">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ minWidth: "150px" }}>Category</TableCell>
                              <TableCell align="center">Billable</TableCell>
                              <TableCell align="center">
                                {formik.values.project.billingType === "tm-t" ? "Hourly rate" : formik.values.project.billingType === "tm-p" ? "Minimum hourly" : "Rate"}
                              </TableCell>
                              <TableCell align="center">Hours budget</TableCell>
                              <TableCell align="center">Fees budget</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <FieldArray
                              name="taskCategories"
                              render={arrayHelpers => {
                                return formik.values.taskCategories.map((obj, index) => (
                                  <TableRow key={obj.taskCategoryId}>
                                    <TableCell component="th" scope="row">
                                      {obj.taskCategoryName}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Switch
                                        checked={Boolean(formik.values.taskCategories[index].isBillable)}
                                        onChange={(e) => {
                                          formik.setFieldValue(`taskCategories[${index}].isBillable`, e.target.checked);

                                          const feesBudget = formik.values.project.projectFeesBudget
                                          const hoursBudget = formik.values.project.projectHoursBudget

                                          if (!e.target.checked) {
                                            formik.setFieldValue(`taskCategories[${index}].rate`, "");
                                            formik.setFieldValue(`taskCategories[${index}].feesBudget`, "");
                                          }
                                          if (e.target.checked) {
                                            if (formik.values.project.perTaskBudgetType === "fees") {
                                              if (feesBudget > 0 && hoursBudget > 0) {
                                                formik.setFieldValue(`taskCategories[${index}].hoursBudget`, feesBudget / hoursBudget);
                                              }
                                            }
                                            if (formik.values.project.perTaskBudgetType === "hours") {
                                              if (feesBudget > 0 && hoursBudget > 0) {
                                                formik.setFieldValue(`taskCategories[${index}].feesBudget`, feesBudget / hoursBudget);
                                              }
                                            }
                                          }
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <FilledInput
                                        type="number"
                                        placeholder="0.00"
                                        disableUnderline
                                        inputProps={{ style: { padding: "5px" } }}
                                        style={{ width: "70%" }}
                                        {...formik.getFieldProps(`taskCategories[${index}].rate`)}
                                        disabled={!formik.values.taskCategories[index].isBillable}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <FilledInput
                                        type="number"
                                        placeholder="0.00"
                                        disableUnderline
                                        inputProps={{ style: { padding: "5px" } }}
                                        style={{ width: "70%" }}
                                        {...formik.getFieldProps(`taskCategories[${index}].hoursBudget`)}
                                        disabled={formik.values.project.perTaskBudgetType === "" ||
                                          formik.values.project.perTaskBudgetType === "none" ||
                                          formik.values.project.perTaskBudgetType === "fees"
                                        }
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <FilledInput
                                        type="number"
                                        placeholder="0.00"
                                        disableUnderline
                                        inputProps={{ style: { padding: "5px" } }}
                                        style={{ width: "70%" }}
                                        {...formik.getFieldProps(`taskCategories[${index}].feesBudget`)}
                                        disabled={!formik.values.taskCategories[index].isBillable ||
                                          formik.values.project.perTaskBudgetType === "" ||
                                          formik.values.project.perTaskBudgetType === "none" ||
                                          formik.values.project.perTaskBudgetType === "hours"
                                        }
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton size="small" onClick={() => {
                                        const removedTaskCatery = arrayHelpers.remove(index);
                                        setCategoryList((state) => {
                                          return ([...state, {
                                            id: removedTaskCatery.taskCategoryId,
                                            title: removedTaskCatery.taskCategoryName
                                          }])
                                        })
                                      }}>
                                        <RemoveCircleIcon fontSize="small" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                              }}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : null}
                  <Grid item xs={4}>
                    <Button variant="outlined" disabled={categoryList.length === 0} onClick={(e) => setAnchorElCategory(e.currentTarget)} fullWidth>Add task category</Button>
                    <FieldArray
                      name="taskCategories"
                      render={arrayHelpers => (
                        <Menu
                          open={Boolean(anchorElCategory)}
                          anchorEl={anchorElCategory}
                          onClose={() => setAnchorElCategory(null)}
                        >
                          {categoryList.map((row) => {
                            return <MenuItem style={{ minWidth: "300px" }} key={row.id}
                              onClick={() => {
                                arrayHelpers.push({
                                  taskCategoryId: row.id,
                                  taskCategoryName: row.title,
                                  isBillable: false,
                                  rate: "",
                                  hoursBudget: "",
                                  feesBudget: ""
                                })
                                const newcategoryList = []
                                categoryList.forEach((value) => {
                                  if (value.id !== row.id) {
                                    newcategoryList.push(value)
                                  }
                                })
                                setCategoryList(newcategoryList)
                                setAnchorElCategory(null);
                              }} >{row.title}</MenuItem>
                          })}
                        </Menu>
                      )}
                    />
                  </Grid>

                </Grid>
              </form>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                onClick={(e) => {
                  if (formik.values.taskCategories.length === 0) {
                    toast("Add at least one task category")
                  } else {
                    formik.handleSubmit(e)
                  }
                }}>
                {activeProject ? "UPDATE PROJECT" : "ADD PROJECT"}
              </Button>
            </DialogActions>
            <ProjectClientFormDialog
              isOpen={projectClientFormDialogIsOpen}
              onClose={toggleProjectClientFormDialogIsOpen}
              onAfterSubmit={(_client) => onAfterClientCreation(formik, _client)}
            />
          </>
        )}
      </Formik>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    projects: state.projects.projects,
    projectFormDialogIsOpen: state.projects.projectFormDialogIsOpen,
    activeProject: state.projects.activeProject,
    user: state.settings.user,
    account: state.settings.account,
    clients: state.clients.clients,
  };
};

export default connect(mapStateToProps, { toggleProjectFormDialog, editProject, fetchClientSheet })(ProjectForm);
