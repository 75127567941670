import React from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { IntlProvider } from "react-intl";
import Main from "./components/layout/main";
import CssBaseline from "@material-ui/core/CssBaseline";
// import HTTPManager from "./components/shared/HTTPManager";
import messages_en from "components/translations/en.json";
import messages_de from "components/translations/de.json";
import "./App.css";
import "./helper/index";

const App = props => {
  let randomKey = Math.random()
    .toString(36)
    .substring(7);

  const messages = {
    de: messages_de,
    en: messages_en
  };
  const language = navigator.language.split(/[-_]/)[0]; // language without region code

  return (
    <GlobalHotKeys>
      <IntlProvider locale={language} messages={messages[language]} >
        <div className="App">
          <CssBaseline />
          {/* <HTTPManager /> */}
          <Main key={randomKey} />
        </div>
      </IntlProvider>
    </GlobalHotKeys>
  );
};

export default App;
