import {
  FETCH_TASK_CATEGORIES,
  CREATE_USER_SESSION,
  EDIT_TASKCATEGORY,
  TOGGLE_SWITCH,
  ARCHIVE_TASKCATEGORY,
  FETCH_EXPENSE_CATEGORIES,
  EDIT_EXPENSECATEGORY,
  TOGGLE_SWITCH_EXPENSE_CATEGORY,
  ARCHIVE_EXPENSECATEGORY,
} from "../actions/settings";

const initState = {
  taskCategories: [],
  expenseCategories: [],
  authUser: {},
  user: {},
  account: {},
  activeProject: false, archiveTask: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case FETCH_TASK_CATEGORIES:
      return { ...state, taskCategories: action.value.sort((a, b) => a.title > b.title ? 1 : (a.title < b.title ? -1 : 0)) };
    case CREATE_USER_SESSION:
      return { ...state, user: action.value.user, authUser: action.value.authUser, account: action.value.account }
    case EDIT_TASKCATEGORY:
      return { ...state, activeProject: action.value };
    case TOGGLE_SWITCH:
      return { ...state, taskCategories: state.taskCategories.map(tc => tc.id === action.value.taskCategoryId ? { ...tc, isEnabled: !action.value.isEnabled } : tc) };
    case ARCHIVE_TASKCATEGORY:
      return { ...state, archiveTask: action.value };
    case FETCH_EXPENSE_CATEGORIES:
      return { ...state, expenseCategories: action.value.sort((a, b) => a.title > b.title ? 1 : (a.title < b.title ? -1 : 0)) };
    case EDIT_EXPENSECATEGORY:
      return { ...state, activeProject: action.value };
    case TOGGLE_SWITCH_EXPENSE_CATEGORY:
      return { ...state, expenseCategories: state.expenseCategories.map(tc => tc.id === action.value.expenseCategoryId ? { ...tc, isEnabled: !action.value.isEnabled } : tc) };
    case ARCHIVE_EXPENSECATEGORY:
      return { ...state, archiveTask: action.value };
    default:
      return state;
  }
};
