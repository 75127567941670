export function pad(num) {
  return ("0" + num).slice(-2);
}

export function hhmm(secs, format, unit = "secs") {
  var minutes = unit === "secs" ? Math.floor(secs / 60) : parseFloat(secs);
  // secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  if (format === "hh, mm") return `${pad(hours)}h, ${pad(minutes)}m`;
  else if (format === "h:mm") return `${hours}:${pad(minutes)}`;
  return `${pad(hours)}:${pad(minutes)}`;
}

export function prefixZero(value) {
  return value < 10 ? `0${value}` : value;
}

// convert seconds to mm:ss
export const convertMS = (value) => {
  const sec = parseInt(value, 10); // convert value to number if it's string and return integer
  let minutes = Math.floor(sec / 60);
  let seconds = sec % 60;
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return minutes + ':' + seconds; // Return is MM : SS
}