export const FETCH_CLIENTSHEET = "FETCH_CLIENTSHEET";
export const TOGGLE_CLIENT_FORM_DIALOG = "TOGGLE_CLIENT_FORM_DIALOG";
export const TOGGLE_CLIENT_CONTACT_FORM_DIALOG = "TOGGLE_CLIENT_CONTACT_FORM_DIALOG";
export const EDIT_CLIENT = "EDIT_CLIENT";



// dispatch fetchTimeSheet with data from backend
export const fetchClientSheet = (clients) => {
    return {
        type: FETCH_CLIENTSHEET,
        value: clients
    };
};

export const toggleClientFormDialog = (bool) => {
    return {
        type: TOGGLE_CLIENT_FORM_DIALOG,
        value: bool
    };
};

export const toggleClientContactFormDialog = (bool) => {
    return {
        type: TOGGLE_CLIENT_CONTACT_FORM_DIALOG,
        value: bool
    };
};

// Edit Client
export const editClient = (client) => {
    return {
        type: EDIT_CLIENT,
        value: client
    };
};
