import React, { useState, useEffect } from "react";
import { Paper, Grid, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import Timer from "components/widgets/timer";
import TwitterTimeline from "components/widgets/twitterTimeline";
import { CustomFormattedMessage } from "asmi-ui-web";
import Pomodoro from "components/widgets/pomodoro";
import { BlogLink, GetMoreFromAsmi } from "components/widgets/extras";
import {
  NavElement,
  NavElementContainer,
  DialogPupup,
  SubNavElement
} from "components/widgets/navElement";
import logo from "./asmi_logo.svg";
import user from "./user.png";
import colors from "constants/colors";
import { toggleClientFormDialog } from "actions/clients";
import { toggleExpenseFormDialog } from "actions/expenses";
import { toggleProjectFormDialog } from "actions/projects";
// import MdiIcon from "@mdi/react";
// import { mdiHelp, mdiShareVariant } from "@mdi/js";
import { useHistory } from "react-router-dom";

function NavBar(props) {
  const { routes, toggleClientFormDialog, toggleExpenseFormDialog, toggleProjectFormDialog } = props
  const [mainMenuSelected, setMainMenuSelected] = useState("");
  const [initialSelection, setInitialSelection] = useState("Home");
  const [currentSelection, setCurrentSelection] = useState("");
  let history = useHistory();

  function handleChange(option, childRoute, path, id) {
    if (id) {
      document.getElementById(id).click();
    } else {
      setMainMenuSelected(option);
      setCurrentSelection(path);

      if (childRoute) {
        setInitialSelection(option);
        history.push(path);
      }
    }
  }

  useEffect(
    function () {
      let current = "";
      let child;
      if (window.location.pathname === "/") {
        current = "Home";
      } else {
        routes.forEach(route => {
          let childRoute;
          childRoute = route.child.find(ch => ch.path === window.location.pathname);
          if (childRoute) {
            current = route.label
            child = current;
          }
        });
      }
      if (child) {
        handleChange(current, true, child.path);
      } else {
        handleChange(current);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [routes]
  );

  useEffect(() => {
    window.Headway.init();
  }, [currentSelection, mainMenuSelected, initialSelection])

  return (
    <Paper
      style={{
        borderRadius: "unset",
        borderTopLeftRadius: "10px",
        overflow: "hidden",
        height: "95vh",
        backgroundColor: colors.grey,
        padding: '0px',
      }}
      onMouseLeave={() => setMainMenuSelected(initialSelection)}
    >
      <Grid container spacing={0} style={{
        marginBottom: '-1px'
      }}>
        <NavElementContainer
          active={mainMenuSelected === "Home"}
          onClick={() => handleChange("Home")}
          // onMouseEnter={() => setMainMenuSelected("Home")}
          compact={true}
        >
          <span
            style={{
              height: 34,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img src={logo} alt="Asmi" style={{ maxHeight: 27 }} />
          </span>
        </NavElementContainer>

        <NavElementContainer
          active={mainMenuSelected === "Profile"}
          onClick={() => handleChange("Profile")}
          // onMouseEnter={() => setMainMenuSelected("Profile")}
          compact={true}
        >
          <img
            src={props.profilePicture ? props.profilePicture : user}
            alt="Profile"
            style={{
              maxHeight: 30,
              borderRadius: "50%",
              border: `2px solid ${colors.primary.main}`,
              marginTop: 2
            }}
          />
        </NavElementContainer>

        <NavElementContainer
          active={mainMenuSelected === "Add"}
          onClick={() => handleChange("Add")}
          // onMouseEnter={() => setMainMenuSelected("Add")}
          compact={true}
        >
          <Icon color="secondary">add_circle</Icon>
        </NavElementContainer>

        {mainMenuSelected === "Add" ? (
          <>
            <DialogPupup onClick={toggleProjectFormDialog.bind(null, true)} route={true}><CustomFormattedMessage textTransform="capitalize" id="generic.project" /></DialogPupup>
            <DialogPupup onClick={toggleClientFormDialog.bind(null, true)} route={true}><CustomFormattedMessage textTransform="capitalize" id="generic.client" /></DialogPupup>
            {/* <DialogPupup onClick={toggleExpenseFormDialog.bind(null, true)} route={true}>Expense</DialogPupup> */}
            {/* <DialogPupup route={true}>Invoice</DialogPupup> */}
          </>
        ) : routes.map(route => {
          // eslint-disable-next-line array-callback-return
          return route.child.map((childRoute, idx) => {
            if (route.label === mainMenuSelected) {
              return (
                <NavElement
                  key={idx}
                  type="link"
                  route={childRoute}
                  onClick={() =>
                    handleChange(mainMenuSelected, true, childRoute.path, childRoute.id)
                  }
                />
              );
            }
          });
        })}
        {/* { mainMenuSelected === "Home" ? <HomeMoreNav /> : null } */}
      </Grid>
      <Timer />
      {/* <Pomodoro /> */}
      {/* <TwitterTimeline /> */}
      {/* <BlogLink /> */}
      {/* <GetMoreFromAsmi /> */}
    </Paper>
  );
}

export default connect(null, { toggleClientFormDialog, toggleExpenseFormDialog, toggleProjectFormDialog })(NavBar);
