import * as apiClient from 'asmi-api-client-js';

export const FETCH_TASK_CATEGORIES = "FETCH_TASK_CATEGORIES";
export const CREATE_USER_SESSION = "CREATE_USER_SESSION";
export const EDIT_TASKCATEGORY = "EDIT_TASKCATEGORY";
export const TOGGLE_SWITCH = "TOGGLE_SWITCH";
export const ARCHIVE_TASKCATEGORY = "ARCHIVE_TASKCATEGORY"
export const UPDATE_TASKCATEGORY = "UPDATE_TASKCATEGORY"
export const ADD_TASKCATEGORY = "ADD_TASKCATEGORY"
export const FETCH_EXPENSE_CATEGORIES = "FETCH_EXPENSE_CATEGORIES";
export const EDIT_EXPENSECATEGORY = "EDIT_EXPENSECATEGORY";
export const TOGGLE_SWITCH_EXPENSE_CATEGORY = "TOGGLE_SWITCH_EXPENSE_CATEGORY";
export const ARCHIVE_EXPENSECATEGORY = "ARCHIVE_EXPENSECATEGORY"
export const UPDATE_EXPENSECATEGORY = "UPDATE_EXPENSECATEGORY"
export const ADD_EXPENSECATEGORY = "ADD_EXPENSECATEGORY"

export const fetchTaskCategories = (forceApi = false, taskCategories) => async (dispatch, getState) => {
  const userId = getState().settings.user.id;
  const accountId = getState().settings.account.id;
  if (!userId || !accountId) {
    return;
  }
  let apiTaskCategories = [];
  if (!forceApi && taskCategories) {
    return dispatch({
      type: FETCH_TASK_CATEGORIES,
      value: taskCategories.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))
    });
  }
  // if (getState().settings.taskCategories.length === 0 || forceApi === true) {
  console.log("fetching task categories");
  apiTaskCategories = (await apiClient.getAllTaskCategories(userId, accountId));
  dispatch({
    type: FETCH_TASK_CATEGORIES,
    value: apiTaskCategories
  });
  // }
}

export const createUserSession = (authUser, user, account) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_USER_SESSION,
    value: {
      authUser,
      user,
      account
    }
  })
}

// Edit TaskCategory
export const editTaskCategory = (taskCategory) => {
  // console.log(taskCategory, "taskedit")
  return {
    type: EDIT_TASKCATEGORY,
    value: taskCategory
  };
};

export const switchOn = (switchOn) => async (dispatch, getState) => {
  dispatch({
    type: TOGGLE_SWITCH,
    value: switchOn
  });
  if (switchOn.isEnabled === true) {
    await apiClient.disableTaskCategory(switchOn.userId, switchOn.taskCategoryId);
  } else {
    await apiClient.enableTaskCategory(switchOn.userId, switchOn.taskCategoryId);
  }
};

export const archiveTaskCategory = (archiveTaskCategory) => async (dispatch, getState) => {
  const userId = getState().settings.user.id;
  const accountId = getState().settings.account.id;
  console.log(archiveTaskCategory.userId, "archive")
  await apiClient.archiveTaskCategory(archiveTaskCategory.userId, archiveTaskCategory.taskCategoryId);
  dispatch({
    type: TOGGLE_SWITCH,
    value: archiveTaskCategory
  });
}

export const updateTaskcategory = (updateTaskcategory) => async (dispatch, getState) => {
  console.log(updateTaskcategory, "update")
  apiClient.updateTaskCategory(updateTaskcategory.userId.userId, updateTaskcategory.userId.taskCategoryId, updateTaskcategory.userId.values)
  dispatch({
    type: UPDATE_TASKCATEGORY,
    value: updateTaskcategory
  });
}

export const addTaskCategory = (addTaskCategory) => async (dispatch, getState) => {
  console.log(addTaskCategory, "update")
  apiClient.addTaskCategory(addTaskCategory.userId.userId, addTaskCategory.userId.accountId, addTaskCategory.userId.values)
  dispatch({
    type: ADD_TASKCATEGORY,
    value: addTaskCategory
  });
}

export const fetchExpenseCategories = (forceApi = false, expenseCategories) => async (dispatch, getState) => {
  const userId = getState().settings.user.id;
  const accountId = getState().settings.account.id;
  if (!userId || !accountId) {
    return;
  }
  let apiExpenseCategories = [];
  if (!forceApi && expenseCategories) {
    return dispatch({
      type: FETCH_EXPENSE_CATEGORIES,
      value: expenseCategories.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))
    });
  }
  // if (getState().settings.expenseCategories.length === 0 || forceApi === true) {
  console.log("fetching expense categories");
  apiExpenseCategories = (await apiClient.getAllExpenseCategories(userId, accountId));
  dispatch({
    type: FETCH_EXPENSE_CATEGORIES,
    value: apiExpenseCategories
  });
  // }
}

// Edit ExpenseCategory
export const editExpenseCategory = (expenseCategory) => {
  // console.log(expenseCategory, "expenseedit")
  return {
    type: EDIT_EXPENSECATEGORY,
    value: expenseCategory
  };
};

export const switchOnExpenseCategory = (switchOn) => async (dispatch, getState) => {
  dispatch({
    type: TOGGLE_SWITCH_EXPENSE_CATEGORY,
    value: switchOn
  });
  if (switchOn.isEnabled === true) {
    await apiClient.disableExpenseCategory(switchOn.userId, switchOn.expenseCategoryId);
  } else {
    await apiClient.enableExpenseCategory(switchOn.userId, switchOn.expenseCategoryId);
  }
};

export const archiveExpenseCategory = (archiveExpenseCategory) => async (dispatch, getState) => {
  const userId = getState().settings.user.id;
  const accountId = getState().settings.account.id;
  console.log(archiveExpenseCategory.userId, "archive")
  await apiClient.archiveExpenseCategory(archiveExpenseCategory.userId, archiveExpenseCategory.expenseCategoryId);
  dispatch({
    type: TOGGLE_SWITCH,
    value: archiveExpenseCategory
  });
}

export const updateExpensecategory = (updateExpensecategory) => async (dispatch, getState) => {
  console.log(updateExpensecategory, "update")
  apiClient.updateExpenseCategory(updateExpensecategory.userId.userId, updateExpensecategory.userId.expenseCategoryId, updateExpensecategory.userId.values)
  dispatch({
    type: UPDATE_EXPENSECATEGORY,
    value: updateExpensecategory
  });
}

export const addExpenseCategory = (addExpenseCategory) => async (dispatch, getState) => {
  console.log(addExpenseCategory, "update")
  apiClient.addExpenseCategory(addExpenseCategory.userId.userId, addExpenseCategory.userId.accountId, addExpenseCategory.userId.values)
  dispatch({
    type: ADD_EXPENSECATEGORY,
    value: addExpenseCategory
  });
}
