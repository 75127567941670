export const FETCH_EXPENSESHEET = "FETCH_EXPENSESHEET";
export const TOGGLE_EXPENSE_FORM_DIALOG = "TOGGLE_EXPENSE_FORM_DIALOG";


const data = {
    data: {
        overall_stats: {
            cost: 5,
            billed: 7,
            unbilled: 3
        },
        expenses: [
            {
                id: 1,
                name: "Client UK",
                projects: [
                    {
                        id: 11,
                        name: "Project Name",
                        date: 898998930309,
                        amount: 2300,
                        type: "Billable",
                        status: "Open",
                        settledDate: 998998930309,
                    },
                    {
                        id: 12,
                        name: "Project Name",
                        date: 898998930309,
                        amount: 2300,
                        type: "Non Billable",
                        status: "Closed",
                        settledDate: 998998930309,
                    }
                ]
            },
            {
                id: 2,
                name: "Client India",
                projects: [
                    {
                        id: 21,
                        name: "Project Name",
                        date: 898998930309,
                        amount: 2300,
                        type: "Billable",
                        status: "Open",
                        settledDate: 998998930309,
                    },
                    {
                        id: 22,
                        name: "Project Name",
                        date: 898998930309,
                        amount: 2300,
                        type: "Non Billable",
                        status: "Closed",
                        settledDate: 998998930309,
                    }
                ]
            },
            {
                id: 3,
                name: "Client Nigeria",
                projects: [
                    {
                        id: 31,
                        name: "Project Name",
                        date: 898998930309,
                        amount: 2300,
                        type: "Billable",
                        status: "Open",
                        settledDate: 998998930309,
                    },
                    {
                        id: 32,
                        name: "Project Name",
                        date: 898998930309,
                        amount: 2300,
                        type: "Non Billable",
                        status: "Closed",
                        settledDate: 998998930309,
                    }
                ]
            },
        ]
    }
};



// dispatch fetchTimeSheet with data from backend
export const fetchExpensesSheet = (/* data from backend */) => {
    return {
        type: FETCH_EXPENSESHEET,
        value: { ...data }
    };
};

// expenceFormDialogIsOpen
export const toggleExpenseFormDialog = (Bool) => {
    return {
        type: TOGGLE_EXPENSE_FORM_DIALOG,
        value: Bool
    };
};
