import {
    COMPANYNAME,
    TIMEZONE,
    WEEKDAY,
    DATEFORMAT,
    TIMEFORMAT,
    TIMEROUNDING,
    NUMBERFORMAT,
    CURRENCIES
} from "../actions/settingAccount";


const initState ={
    companyName:{},
    timeZone:{},
    weekDay :{},
    dateFormat:{},
    timeFormat:{},
    timeRounding:{},
    numberFormat:{},
    currenciesData:{},

};

export default (state = initState, action) => {
    switch (action.type) {
        case COMPANYNAME:
            console.log(action.value,'reducer')
            return { ...state,
                companyName:action.value};
        case TIMEZONE:
            console.log(action.value,'reducer')
            return { ...state,
                timeZone:action.value};
        case WEEKDAY:
            console.log(action.value,'reducer')
            return { ...state,
                weekDay:action.value};                
        case DATEFORMAT:
            console.log(action.value,'reducer')
            return { ...state,
                dateFormat:action.value};  
        case TIMEFORMAT:
            console.log(action.value,'reducer')
            return { ...state,
                timeFormat:action.value}; 
        case TIMEROUNDING:
            console.log(action.value,'reducer')
            return { ...state,
                timeRounding:action.value};
        case NUMBERFORMAT:
            console.log(action.value,'reducer')
            return { ...state,
                numberFormat:action.value}; 
        case CURRENCIES:
            console.log(action.value,'reducer')
            return { ...state,
                currenciesData:action.value};    
        default:
            return state;
    }
};