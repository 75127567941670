import React from "react";
import {
  Typography,
  Icon,
  Popover,
  Button,
  Grid,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import WidgetContainer from "./widgetContainer";
import moment from "moment";
import { CustomFormattedMessage } from "asmi-ui-web";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import ErrorLabel from "components/shared/ErrorLabel";
import * as Yup from "yup";
import StartStop from "./startStop";

const styles = theme => ({
  icon: {
    display: "block",
    cursor: "pointer",
    "&:first-child": {
      marginBottom: 7,
      fontSize: "1.75rem"
    }
  },
  popover: {
    padding: 20,
    width: "350px"
  },
  title: {
    fontWeight: 700
  },
  select: {
    width: "100%"
  },
  grid: {
    padding: "10px 10px 10px 0"
  },
  rightAlign: {
    display: "flex",
    justifyContent: "flex-end"
  },
  cursor: {
    cursor: "pointer"
  },
  adornment: {
    textTransform: "lowercase"
  }
});

const requiredError = (
  <CustomFormattedMessage
    textTransform="capitalize"
    id="generic.form.error.required"
  />
);

const validationSchema = Yup.object().shape({
  project: Yup.string()
    .min(2, requiredError)
    .required(requiredError),
  task: Yup.string()
    .min(2, requiredError)
    .required(requiredError)
});

var settings = {
  duration: {
    //in minutes
    pomodoro: 25,
    short_break: 5,
    long_break: 15
  },
  long_break_delay: 4
};

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return (
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds
  );
}

function minstoHoursAndMins(minutes) {
  var hours = Math.floor(minutes / 60);
  var mins = (minutes % 60).toFixed(0);
  return (hours < 10 ? "0" : "") + hours + ":" + (mins < 10 ? "0" : "") + mins;
}

class Pomodoro extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      running: false,
      activeEntry: "",
      timerStartedAt: null,
      timeElasped: "--:--",
      addTimerDialog: false,
      anchorEl: null,
      project: null,
      allEntries: [],
      completedPomodoros: 0,
      roundsToday: 0
    };
  }

  startPomodoro = event => {
    this.setState(
      { running: true, activeEntry: "pomodoro", timerStartedAt: new Date() },
      () => {
        setInterval(() => {
          const { running, activeEntry } = this.state;
          let { timerStartedAt, completedPomodoros, roundsToday } = this.state;
          let diff = moment().diff(moment(timerStartedAt));
          let newActiveEntry = activeEntry;

          if (running) {
            if (diff >= settings.duration[activeEntry] * 60000) {
              switch (activeEntry) {
                case "pomodoro":
                  completedPomodoros = completedPomodoros + 1;
                  roundsToday = roundsToday + 1;
                  if (
                    completedPomodoros !== 0 &&
                    completedPomodoros % settings.long_break_delay === 0
                  )
                    newActiveEntry = "long_break";
                  else newActiveEntry = "short_break";
                  break;
                case "short_break":
                case "long_break":
                default:
                  newActiveEntry = "pomodoro";
                  break;
              }
              timerStartedAt = new Date();
              diff = 0;
            }
            this.setState({
              timeElasped: millisToMinutesAndSeconds(diff),
              activeEntry: newActiveEntry,
              timerStartedAt,
              completedPomodoros,
              roundsToday
            });
          }
        }, 1000);
      }
    );
  };

  stopPomodoro = roundsToday => {
    this.setState(prevState => ({
      activeEntry: "",
      running: false,
      timerStartedAt: null,
      timeElasped: "--:--",
      completedPomodoros: 0,
      roundsToday: roundsToday ? roundsToday : prevState.roundsToday
    }));
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  saveSettings = values => {
    settings = { ...values };
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      anchorEl,
      running,
      activeEntry,
      timeElasped,
      roundsToday
    } = this.state;
    const { classes } = this.props;
    const open = Boolean(anchorEl);
    const id = open ? "settings-popover" : undefined;
    const activeEntryLbl =
      activeEntry === ""
        ? "generic.getStarted"
        : activeEntry === "pomodoro"
          ? "generic.pomodoro"
          : activeEntry === "short_break"
            ? "widgets.pomodoro.lblShortBreak"
            : "widgets.pomodoro.lblLongBreak";

    return (
      <WidgetContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Typography variant="subtitle1">
              <CustomFormattedMessage
                textTransform="capitalize"
                id="generic.pomodoro"
              />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                id="widgets.pomodoro.timeElasped"
                values={{ timeElasped: timeElasped }}
              />
              {activeEntry !== "" ? (
                <CustomFormattedMessage
                  id={activeEntryLbl}
                  textTransform="capitalize"
                >
                  {msg => ", " + msg}
                </CustomFormattedMessage>
              ) : null}
            </Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="widgets.pomodoro.todaySummary"
                values={{
                  totalRounds: roundsToday,
                  totalTime: minstoHoursAndMins(
                    roundsToday * settings.duration.pomodoro
                  )
                }}
              />
            </Typography>
          </div>
          <div align="center">
            <Icon
              className={classes.icon}
              fontSize="small"
              onClick={this.handleClick}
            >
              tune
            </Icon>
            <StartStop
              onStop={e => this.stopPomodoro()}
              onStart={this.startPomodoro}
              running={running}
            />
          </div>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          style={{ marginLeft: 18 }}
        >
          <div className={classes.popover}>
            <Typography className={classes.title}>
              <CustomFormattedMessage
                textTransform="capitalize"
                id="generic.settings"
              />
            </Typography>
            <Formik
              initialValues={{
                ...settings
              }}
              onSubmit={this.saveSettings}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item xs={4} className={classes.grid}>
                        <CustomFormattedMessage
                          textTransform="capitalize"
                          id="widgets.pomodoro.lblFocusTime"
                        >
                          {msg => (
                            <TextField
                              name="duration.pomodoro"
                              value={values.duration.pomodoro}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={classes.select}
                              placeholder={"0"}
                              label={msg}
                              type="number"
                              InputProps={{
                                step: 1,
                                min: 0,
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.adornment}
                                    disableTypography
                                  >
                                    mins
                                  </InputAdornment>
                                )
                              }}
                            ></TextField>
                          )}
                        </CustomFormattedMessage>
                      </Grid>
                      <Grid item xs={4} className={classes.grid}>
                        <CustomFormattedMessage
                          id="widgets.pomodoro.lblShortBreak"
                          textTransform="capitalize"
                        >
                          {msg => (
                            <TextField
                              name="duration.short_break"
                              value={values.duration.short_break}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={classes.select}
                              placeholder={"0"}
                              label={msg}
                              type="number"
                              InputProps={{
                                step: 1,
                                min: 0,
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.adornment}
                                    disableTypography
                                  >
                                    mins
                                  </InputAdornment>
                                )
                              }}
                            ></TextField>
                          )}
                        </CustomFormattedMessage>
                      </Grid>
                      <Grid item xs={4} className={classes.grid}>
                        <CustomFormattedMessage
                          id="widgets.pomodoro.lblLongBreak"
                          textTransform="capitalize"
                        >
                          {msg => (
                            <TextField
                              name="duration.long_break"
                              value={values.duration.long_break}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={classes.select}
                              placeholder={"0"}
                              label={msg}
                              type="number"
                              InputProps={{
                                step: 1,
                                min: 0,
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.adornment}
                                    disableTypography
                                  >
                                    mins
                                  </InputAdornment>
                                )
                              }}
                            ></TextField>
                          )}
                        </CustomFormattedMessage>
                        {errors.project && touched.project ? (
                          <ErrorLabel>{errors.project}</ErrorLabel>
                        ) : null}
                      </Grid>
                      <Grid item xs={6} className={classes.grid}>
                        <CustomFormattedMessage
                          id="widgets.pomodoro.lblLongBreakDelay"
                          textTransform="capitalize"
                        >
                          {msg => (
                            <TextField
                              name="long_break_delay"
                              value={values.long_break_delay}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={classes.select}
                              placeholder={"0"}
                              label={msg}
                              type="number"
                              InputProps={{
                                step: 1,
                                min: 0,
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.adornment}
                                    disableTypography
                                  >
                                    pomodoros
                                  </InputAdornment>
                                )
                              }}
                            ></TextField>
                          )}
                        </CustomFormattedMessage>
                      </Grid>
                      {/*<Grid item xs={12} className={classes.grid}>
                      <Typography variant="body2">
                        <Checkbox
                          name="auto_start"
                          color="primary"
                          style={{ padding: 0, paddingRight: 5 }}
                        />
                        <FormattedMessage id="widgets.pomodoro.lblAutoStart"></FormattedMessage>
                      </Typography>
                    </Grid>*/}
                      <Grid
                        item
                        xs={12}
                        className={`${classes.grid} ${classes.rightAlign}`}
                      >
                        <Button type="submit" color="secondary">
                          <CustomFormattedMessage
                            textTransform="uppercase"
                            id="generic.btnSave"
                          />
                        </Button>
                        <Button
                          onClick={this.handleClose}
                          style={{ marginLeft: 5 }}
                        >
                          <CustomFormattedMessage
                            textTransform="uppercase"
                            id="generic.btnCancel"
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
            </Formik>
          </div>
        </Popover>
      </WidgetContainer>
    );
  }
}

export default withStyles(styles)(Pomodoro);
