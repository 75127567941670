import {
    FETCH_EXPENSESHEET,
    TOGGLE_EXPENSE_FORM_DIALOG
} from "../actions/expenses";


const initState = {
    data: { overall_stats: {}, expenses: [] },
    expenseFormDialogIsOpen: false,
};



export default (state = initState, action) => {
    switch (action.type) {

        case FETCH_EXPENSESHEET:
            return { ...state, ...action.value };

        case TOGGLE_EXPENSE_FORM_DIALOG:
            return { ...state, expenseFormDialogIsOpen: action.value };

        default:
            return state;
    }
};
