export const FETCH_INVOICESSHEET = "FETCH_INVOICESSHEET";



const data = {
    data: {
        overall_stats: {
            open: 5,
            monthlyAverage: 7000,
            waived: 6500,
            totalEarned: 550
        },
        invoices: [
            {
                id: 1,
                client: "Client Name",
                status: "Paid",
                date: 1587666796362,
                dueDate: 1597996796362,
                amount: 40000,
                waived: 10098,
                received: 6000,
                balance: 6000,
            },
            {
                id: 2,
                client: "Client Name",
                status: "waiting",
                date: 1587856796362,
                dueDate: 1589656796362,
                amount: 40000,
                waived: 10098,
                received: 6000,
                balance: 6000,
            },
            {
                id: 3,
                client: "Client Name",
                status: "Paid",
                date: 1537656796362,
                dueDate: 1567656796362,
                amount: 30000,
                waived: 1500,
                received: 4000,
                balance: 12000,
            },
            {
                id: 4,
                client: "Client Name",
                status: "Paid",
                date: 1587656996362,
                dueDate: 1597658998362,
                amount: 25000,
                waived: 800,
                received: 7000,
                balance: 4000,
            },
        ]
    }
};



// dispatch fetchTimeSheet with data from backend
export const fetchInvoicesSheet = (/* data from backend */) => {
    return {
        type: FETCH_INVOICESSHEET,
        value: { ...data }
    };
};
