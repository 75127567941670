import {
    CLIENTDATA,
} from "../actions/settingsTeam";



const initState = {
    data: { clients: [] },
};


export default (state = initState, action) => {
    switch (action.type) {
        case CLIENTDATA:
            return { ...state, ...action.value };

        default:
            return state;
    }
};