import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import * as Yup from "yup";
import { Formik } from 'formik';
import { toggleClientFormDialog, toggleClientContactFormDialog, editClient } from "actions/clients";
import * as apiClient from 'asmi-api-client-js';
import { toast } from "react-toastify";
import LoadingSpinner from "components/shared/loadingSpinner"
import { FormattedList } from 'react-intl';


const useStyles = makeStyles((theme) => ({
  headerTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(3),
  },
  loadingSpinner: {
    top: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    borderRadius: "4px",
    backgroundColor: "#f7f8f8"
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too short!').max(50, 'Too long!').required('First name is required'),
  lastName: Yup.string().max(50, 'Too long!').required('Last name is required'),
  email: Yup.string().email("Should be an email").required('Email is required'),
});

const ClientForm = (props) => {
  const classes = useStyles();
  const afterSubmit = useRef(null);
  const isMounted = useRef(true);
  const {
    user,
    account,
    activeClient,
    editClient,
    clientFormDialogIsOpen,
    toggleClientFormDialog,
    toggleClientContactFormDialog,
  } = props;
  const [isMailing, setMailing] = useState(false);
  const [isBusiness, setBusiness] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    notes: "",
    mailingAddress: {
      city: "",
      country: "",
      line1: "",
      line2: "",
      postalCode: "",
      state: ""
    },
    businessName: "",
    //taxID: "",
    billingAddress: {
      city: "",
      country: "",
      line1: "",
      line2: "",
      postalCode: "",
      state: ""
    },
  });

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (activeClient) {
      setIsLoading(true)
      apiClient.getClient(activeClient.userId, activeClient.clientId)
        .then(response => {
          if (isMounted.current) {
            setInitialValues({
              firstName: response.primaryContact && response.primaryContact.firstName,
              lastName: response.primaryContact && response.primaryContact.lastName,
              email: response.primaryContact && response.primaryContact.email,
              phone: response.primaryContact && response.primaryContact.phone,
              notes: response.primaryContact && response.primaryContact.notes,
              mailingAddress: response.primaryContact && response.primaryContact.mailingAddress,
              businessName: response.client && response.client.businessName,
              //taxID: "",
              billingAddress: response.client && response.client.billingAddress
            });
            setMailing(true);
            setBusiness(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            toast(error.message);
            setIsLoading(false);
          }
        })
    }

    return () => {
      if (afterSubmit.current !== "open") {
        editClient(false);
      }
    }
  }, [activeClient, editClient])

  const onCloseHandler = useCallback(() => {
    toggleClientFormDialog(false);
  }, [toggleClientFormDialog])

  return (
    <Dialog
      open={clientFormDialogIsOpen}
      onClose={onCloseHandler}
      fullWidth
      maxWidth="sm"
      TransitionComponent={Transition}
      aria-labelledby="Client Form"
      aria-describedby="Add new Client Desc"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, formikBag) => {
          setIsLoading(true);
          if (activeClient) {
            try {
              await apiClient.updateClient(activeClient.userId, activeClient.clientId, values);
              if (isMounted.current) {
                if (afterSubmit.current === "close") {
                  toggleClientFormDialog(false);
                }
                if (afterSubmit.current === "open") {
                  toggleClientContactFormDialog(true);
                  toggleClientFormDialog(false);
                }
              }

            } catch (error) {
              if (isMounted.current) {
                toast(error.message);
                setIsLoading(false);
              }
            }

          } else {
            try {
              const client = await apiClient.createClient(user.id, account.id, values);
              if (isMounted.current) {
                if (afterSubmit.current === "close") {
                  toggleClientFormDialog(false);
                }
                if (afterSubmit.current === "open") {
                  toggleClientFormDialog(false);
                  editClient({ userId: user.id, clientId: client.primaryContact.clientId, clientName: client.client.name })
                  toggleClientContactFormDialog(true);
                }
              }

            } catch (error) {
              if (isMounted.current) {
                toast(error.message);
                setIsLoading(false);
              }
            }
          }

        }}>
        {(formik) => (
          <>
            <LoadingSpinner isLoading={isLoading} radius={true} light={true} />

            <div className={classes.headerTitle} >
              <Typography variant="h1">{activeClient ? `Update client ${activeClient.clientName}` : "Add Client"}</Typography>
              <IconButton onClick={onCloseHandler}>
                <CancelIcon color="secondary" />
              </IconButton>
            </div>
            <Divider />
            <DialogContent>
              <form >
                <Grid container spacing={2} >
                  {/* <Grid item xs={12}>
                    <DialogContentText className={classes.dialogContentText}>Contact Person 1</DialogContentText>
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      label="First name"
                      name="firstName"
                      type="text"
                      placeholder="First name"
                      {...formik.getFieldProps("firstName")}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                      error={formik.touched.firstName && formik.errors.firstName ? true : false}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Last name"
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                      {...formik.getFieldProps("lastName")}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                      error={formik.touched.lastName && formik.errors.lastName ? true : false}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Email address"
                      name="email"
                      type="email"
                      placeholder="Email address"
                      {...formik.getFieldProps("email")}
                      helperText={formik.touched.email && formik.errors.email}
                      error={formik.touched.email && formik.errors.email ? true : false}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Phone"
                      name="phone"
                      type="tel"
                      placeholder="Phone"
                      value={formik.values.phone}
                      onChange={(event) => {
                        let valid = /^\+?\d{0,15}$/.test(event.target.value);
                        if (valid) formik.setFieldValue("phone", event.target.value);
                      }}
                      helperText={""}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Notes"
                      name="notes"
                      type="text"
                      placeholder="Notes"
                      {...formik.getFieldProps("notes")}
                      helperText={""}
                      variant="filled"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  {isMailing ? (
                    <>
                      <Grid item xs={12} className={classes.headerTitle}>
                        <Typography variant="subtitle1">Mailing Address</Typography>
                        <IconButton onClick={setMailing.bind(null, false)}>
                          <RemoveCircleOutlineIcon color="secondary" fontSize="small" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="City"
                          name="mailingAddress.city"
                          type="text"
                          placeholder="City"
                          {...formik.getFieldProps("mailingAddress.city")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Country"
                          name="mailingAddress.country"
                          type="text"
                          placeholder="Country"
                          {...formik.getFieldProps("mailingAddress.country")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Line 1"
                          name="mailingAddress.line1"
                          type="text"
                          placeholder="Line 1"
                          {...formik.getFieldProps("mailingAddress.line1")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Line 2"
                          name="mailingAddress.line2"
                          type="text"
                          placeholder="Line 2"
                          {...formik.getFieldProps("mailingAddress.line2")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Postal Code"
                          name="mailingAddress.postalCode"
                          type="text"
                          placeholder="Postal Code"
                          {...formik.getFieldProps("mailingAddress.postalCode")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="State"
                          name="mailingAddress.state"
                          type="text"
                          placeholder="State"
                          {...formik.getFieldProps("mailingAddress.state")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                    </>
                  ) : (
                      <Button style={{ marginLeft: "8px", marginRight: "4px", marginBottom: "8px" }} onClick={setMailing.bind(null, true)} variant="outlined">
                        ADD MAILING ADDRESS
                      </Button>
                    )}

                  {isBusiness ? (
                    <>
                      <Grid item xs={12} className={classes.headerTitle}>
                        <Typography variant="subtitle1">Business Details</Typography>
                        <IconButton onClick={setBusiness.bind(null, false)}>
                          <RemoveCircleOutlineIcon color="secondary" fontSize="small" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Business name"
                          name="businessName"
                          type="text"
                          placeholder="Business name"
                          {...formik.getFieldProps("businessName")}
                          helperText={"Name of the client Eg Apple Inc"}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Billing Address</Typography>
                      </Grid>

                      {isMailing && (
                        <Grid item xs={12}>
                          <Switch
                            checked={checked}
                            name="checkedA"
                            onChange={(e) => {
                              if (e.target.checked) {
                                formik.setFieldValue("billingAddress.city", formik.values.mailingAddress.city);
                                formik.setFieldValue("billingAddress.country", formik.values.mailingAddress.country);
                                formik.setFieldValue("billingAddress.line1", formik.values.mailingAddress.line1);
                                formik.setFieldValue("billingAddress.line2", formik.values.mailingAddress.line2);
                                formik.setFieldValue("billingAddress.postalCode", formik.values.mailingAddress.postalCode);
                                formik.setFieldValue("billingAddress.state", formik.values.mailingAddress.state);
                              } else {
                                formik.setFieldValue("billingAddress.city", "");
                                formik.setFieldValue("billingAddress.country", "");
                                formik.setFieldValue("billingAddress.line1", "");
                                formik.setFieldValue("billingAddress.line2", "");
                                formik.setFieldValue("billingAddress.postalCode", "");
                                formik.setFieldValue("billingAddress.state", "");
                              }
                              setChecked(e.target.checked)
                            }}
                          />
                          <span className="MuiFormHelperText-root MuiFormHelperText-contained" style={{ margin: "0px" }}>Same as mailing address</span>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <TextField
                          label="City"
                          name="billingAddress.city"
                          type="text"
                          placeholder="City"
                          {...formik.getFieldProps("billingAddress.city")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Country"
                          name="billingAddress.country"
                          type="text"
                          placeholder="Country"
                          {...formik.getFieldProps("billingAddress.country")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Line 1"
                          name="billingAddress.line1"
                          type="text"
                          placeholder="Line 1"
                          {...formik.getFieldProps("billingAddress.line1")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Line 2"
                          name="billingAddress.line2"
                          type="text"
                          placeholder="Line 2"
                          {...formik.getFieldProps("billingAddress.line2")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Postal Code"
                          name="billingAddress.postalCode"
                          type="text"
                          placeholder="Postal Code"
                          {...formik.getFieldProps("billingAddress.postalCode")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="State"
                          name="billingAddress.state"
                          type="text"
                          placeholder="State"
                          {...formik.getFieldProps("billingAddress.state")}
                          helperText={""}
                          variant="filled"
                          fullWidth
                          multiline
                        />
                      </Grid>
                    </>
                  ) : (
                      <Button style={{ marginBottom: "8px" }} onClick={setBusiness.bind(null, true)} variant="outlined">
                        ADD BUSINESS DETAILS
                      </Button>
                    )}

                </Grid>
              </form>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={() => {
                afterSubmit.current = "close";
                formik.handleSubmit()
              }} variant="outlined">
                {activeClient ? "UPDATE AND CLOSE" : "SAVE AND CLOSE"}
              </Button>
              <Button onClick={() => {
                afterSubmit.current = "open";
                formik.handleSubmit();
              }} variant="contained">
                {activeClient ? "UPDATE AND ADD MORE CONTACTS" : "SAVE AND ADD MORE CONTACTS"}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    clientFormDialogIsOpen: state.clients.clientFormDialogIsOpen,
    activeClient: state.clients.activeClient,
    user: state.settings.user,
    account: state.settings.account
  };
};

export default connect(mapStateToProps, { toggleClientFormDialog, toggleClientContactFormDialog, editClient })(ClientForm);


// client:
//   accountId: "ax6MZWI45dVyThmi8GaGiaPV9WI2"
//   billingAddress:
//      city: "Onitsha"
//      country: "Nigeria"
//      line1: "042455123"
//      line2: "04245678"
//      postalCode: "434234"
//      state: "Anambra"
//   businessName: "Evergreen Solution"
//   id: "m8rChQMPaVuLCMUQDY61"
//   isArchived: false
//   name: "Evergreen Solution"
//   primaryContactId: "Z93Sc7y8xCULFmHuXvRG"
//   primaryContactName: "Evergreen George"
// primaryContact:
//   clientId: "m8rChQMPaVuLCMUQDY61"
//   email: "admin@admin.com"
//   firstName: "Evergreen"
//   id: "Z93Sc7y8xCULFmHuXvRG"
//   isArchived: false
//   lastName: "George"
//   mailingAddress:
//       city: "Lagos"
//       country: "Nigeria"
//       line1: "042455123"
//       line2: "04245678"
//       postalCode: "434234"
//       state: "Lagos"
//   notes: "Test Test"
//   phone: 8161272805
