import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import * as apiClient from 'asmi-api-client-js';
import { convertMS } from "components/shared/utilities";

export const FETCH_TIMESHEET = "FETCH_TIMESHEET";
export const FETCH_TIMESHEET_LOADING = "FETCH_TIMESHEET_LOADING";
export const TIMESHEET_EXCEL_CSV = "TIMESHEET_EXCEL_CSV";
export const SET_TIMER_STATE = "SET_TIMER_STATE";
export const SET_ACTIVE_TIMER_STATE = "SET_ACTIVE_TIMER_STATE";
export const UPDATING_TIME_ENTRY = "UPDATING_TIME_ENTRY";
export const UPDATED_TIME_ENTRY = "UPDATED_TIME_ENTRY";
export const SAVE_TIMER_ENTRY = "SAVE_TIMER_ENTRY";
export const DELETE_TIME_ENTRY = "DELETE_TIME_ENTRY";
export const INCREASE_DURATION = "INCREASE_DURATION";
export const TOGGLE_TIMER_POPOVER = "TOGGLE_TIMER_POPOVER";
export const LOADED_ACTIVE_TIMER_ENTRY = "LOADED_ACTIVE_TIMER_ENTRY";
export const CHANGE_TODAY = "CHANGE_TODAY";

let timerStartIntervalID;

export const changeToday = () => async dispatch => {
  setInterval(() => {
    dispatch({
      type: CHANGE_TODAY
    });
  }, 5000);
};

export const fetchActiveTimer = () => async (dispatch, getState) => {
  const userId = getState().settings.user.id;
  if (!userId) {
    return;
  }
  const runningEntries = await apiClient.getActiveTimeEntry(userId);
  if (runningEntries.length > 0) {
    const runningEntry = runningEntries[0];
    let duration = runningEntry.duration || 0;
    let startTime = runningEntry.durationResetTime ? moment(runningEntry.durationResetTime) : moment(runningEntry.startTime);
    duration += moment.duration(moment().diff(startTime)).asSeconds();
    runningEntry.duration = duration;
    dispatch(startTimer(runningEntry, true));
  }
  dispatch({
    type: LOADED_ACTIVE_TIMER_ENTRY
  });
}

export const fetchTimesheet = (grouped, startDate, endDate, selectedDateRangeOption) => async (dispatch, getState) => {
  const userId = getState().settings.user.id;
  if (!userId) {
    return;
  }
  dispatch({
    type: FETCH_TIMESHEET_LOADING
  });

  const filters = getState().time.data.filters;
  if (!startDate) {
    grouped = filters.grouped;
    startDate = filters.startDate;
    endDate = filters.endDate;
    selectedDateRangeOption = filters.selectedDateRangeOption;
  }
  const payload = {
    startDateString: startDate.format('YYYY-MM-DD'),
    dateFormat: 'YYYY-MM-DD',
    // TODO: pick from user preferences
    timezone: moment.tz.guess()
  }
  if (endDate) {
    payload.endDateString = endDate.format('YYYY-MM-DD');
  }

  const today = getState().time.todayMoment;

  const timeSheets = (await apiClient.filterTimeEntries(userId, payload));

  const timesheetExcelCSV = timeSheets.map(value => ({
    date: moment(value.entryDate).format("DD, MMM yyyy"),
    project: value.projectName,
    taskCategory: value.taskCategoryName,
    title: value.title,
    duration: Math.round((value.duration / 60) * 100) / 100
  }))

  dispatch({
    type: TIMESHEET_EXCEL_CSV,
    value: timesheetExcelCSV
  });

  const timeSheet = timeSheets.map(entry => {
    if (moment(entry.entryDate).isSame(today, 'day')) {
      return { ...entry, today: true }
    }
    return entry;
  });

  const response = {
    meta: {},
    data: {
      overall_stats: {},
      filters: {
        grouped,
        startDate,
        endDate,
        selectedDateRangeOption
      }
    }
  }
  if (grouped) {
    // const runningEntry = timeSheet.find(te => te.state === 'running');
    const groupedProjects = _.groupBy(timeSheet, 'projectId');
    const projectIds = Object.keys(groupedProjects);
    response.data.projects = projectIds.map(projectId => {
      const projectTimeEntries = groupedProjects[projectId];
      return {
        id: projectId,
        name: projectTimeEntries[0].projectName,
        clientName: projectTimeEntries[0].clientName,
        duration: projectTimeEntries.reduce((acc, val) => acc + val.duration + (val.durationResetTime && val.state === 'running' ? moment.duration(moment().diff(moment(val.durationResetTime))).asSeconds() : 0), 0),
        tasks: projectTimeEntries
      }
    });
    response.data.overall_stats.duration = response.data.projects.reduce((acc, val) => acc + val.duration, 0);
    response.data.overall_stats.projects = projectIds.length;
    response.data.overall_stats.tasks = timeSheet.length;
  }
  dispatch({
    type: FETCH_TIMESHEET,
    value: { ...response }
  });
};

export const setTimerState = task => {
  return {
    type: SET_ACTIVE_TIMER_STATE,
    value: { ...task }
  };
};

export const addTimeEntry = entry => async (dispatch, getState) => {
  const userId = getState().settings.user.id;
  const activeEntry = getState().time.timer.activeEntry;
  if (!entry.duration) {
    if (activeEntry && activeEntry.id) {
      console.log("stopping current timer");
      dispatch({
        type: SAVE_TIMER_ENTRY,
        value: { ...activeEntry }
      });
    }
    console.log("new with timer...");
    dispatch(startTimer({ id: 'new', ...entry }));
  }
  const timeEntry = await apiClient.addTimeEntry(userId, entry);
  if (!entry.duration) {
    dispatch(startTimer(timeEntry, true));
  }
  const filters = getState().time.data.filters;
  const currentDate = getState().time.todayMoment;
  if (!filters.endDate || currentDate.isBetween(filters.startDate, filters.endDate)) {
    console.log("loading...");
    dispatch(fetchTimesheet());
  }
}

export const updateTimeEntry = (id, { duration, durationEdited, projectId, projectName, state, taskCategoryId, taskCategoryName, title }) => async (dispatch, getState) => {
  console.log('update...', id, taskCategoryName);
  const entry = {
    id,
    projectId,
    projectName,
    state,
    taskCategoryId,
    taskCategoryName,
    title,
    duration
  };
  dispatch({
    type: UPDATING_TIME_ENTRY,
    value: entry
  });
  if (getState().time.timer.activeEntry.id === entry.id) {
    dispatch(setTimerState(entry));
  }
  const userId = getState().settings.user.id;
  await apiClient.updateTimeEntry(userId, id, entry);
  if (durationEdited) {
    console.log("editing duration");
    await apiClient.updateTimeEntryDuration(userId, id, entry.duration);
  }
  await dispatch(fetchTimesheet());
  dispatch({
    type: UPDATED_TIME_ENTRY,
    value: entry
  });
}

export const saveTimerEntry = task => async (dispatch, getState) => {
  dispatch({
    type: SAVE_TIMER_ENTRY,
    value: { ...task }
  });
  const userId = getState().settings.user.id;
  try {
    if (task.id) {
      await apiClient.stopTimeEntry(userId, task.id);
    }
  } catch (e) {
    console.log(e);
  }
};

const increaseDuration = task => {
  return {
    type: INCREASE_DURATION,
    value: { increment: 1 }
  }
}

export const startTimer = (task, ignoreServerCall = false) => async (dispatch, getState) => {
  console.log('start', task.id);

  if (timerStartIntervalID) clearInterval(timerStartIntervalID);

  task.state = 'running';
  dispatch(setTimerState(task));

  timerStartIntervalID = setInterval(() => {
    dispatch(increaseDuration(task))
  }, 1000);

  const userId = getState().settings.user.id;
  try {
    if (task.id !== 'new' && !ignoreServerCall) {
      await apiClient.startTimeEntry(userId, task.id);
    }
  } catch (e) {
    console.log(e);
    dispatch(stopTimer(task));
  }
}

export const stopTimer = task => async dispatch => {
  console.log("stop", task.id);
  dispatch(saveTimerEntry(task));
  clearInterval(timerStartIntervalID);
}

export const deleteTimeEntry = (projectId, taskId) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_TIME_ENTRY,
    value: {
      projectId,
      taskId
    }
  });
  if (getState().time.timer.activeEntry.id === taskId) {
    dispatch(setTimerState({}));
  }
  const userId = getState().settings.user.id;
  await apiClient.deleteTimeEntry(userId, taskId);
  dispatch(fetchTimesheet());
}

export const toggleTimerPopup = (entryType, task = {}, anchorEl, transformOrigin = 'left') => {
  return {
    type: TOGGLE_TIMER_POPOVER,
    task,
    value: {
      entryType,
      anchorEl,
      transformOrigin
    }
  }
}
