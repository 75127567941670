import React from "react";
import { colors } from "asmi-ui-web";
import IconButton from "@material-ui/core/IconButton";
// import MdiIcon from "@mdi/react";
import { PlayArrow, Stop } from "@material-ui/icons";
// import { mdiStopCircle, mdiPlayCircle, mdiStop, mdiPlay } from "@mdi/js";

const StartStop = ({
  onClick,
  running,
  disabled,
  size,
  noCircle,
  color,
  ...props
}, ref) => {
  return <IconButton
    ref={ref}
    size="small"
    onClick={onClick}
    disabled={disabled}
    style={{
      color: colors.primary.contrastText,
      backgroundColor: running ? colors.secondary.main : (noCircle === true ? '#ffffff00' : colors.secondary.main),
      opacity: disabled ? 0.5 : 1
    }}
  >
    {running ? <Stop style={{ fontSize: '16px', margin: '1px' }} /> : <PlayArrow fontSize="inherit" />}
  </IconButton>
};

export default React.forwardRef(StartStop);
