import React, { useState, useCallback } from "react";
import firebase from "firebase/app";
import { connect } from "react-redux"
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { withStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { ToastContainer } from 'react-toastify';
import { userRoutes } from "config/routes";
import NotFound from "./notFound";
import bgImg from "./asmi-bg1.jpg";
import NavBar from "./navbar";
import {
  createUserSession
} from "actions/settings";
import {
  addTimeEntry,
  updateTimeEntry,
  toggleTimerPopup
} from "actions/time";
import { darkTheme } from 'asmi-ui-web';
import apiClient from 'asmi-api-client-js';

import ClientFormDialog from "components/clients/forms/clientFormDialog";
import ClientContactFormDialog from "components/clients/forms/clientContactFormDialog";
import ExpenseFormDialog from "components/expenses/forms/expenceFormDialog";
import ProjectFormDialog from "components/projects/forms/ProjectFormDialog";
import TimerPopover from "components/widgets/timerPopover";

const drawerWidth = 240;
let authIsInit = false;

const Main = props => {
  const {
    classes,
    projectFormDialogIsOpen,
    user,
    authUser,
    createUserSession,
    timer,
    activeEntry,
    editEntry,
    addTimeEntry,
    updateTimeEntry,
    toggleTimerPopup,
    clientFormDialogIsOpen,
    clientContactFormDialogIsOpen
  } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  let routes = userRoutes;

  const token = new URL(window.location.href).searchParams.get('t');
  const auth = firebase.auth();

  if (!authIsInit) {
    auth.onAuthStateChanged(async function (user) {
      if (user && user.email) {
        if (!isLoggedIn) {
          const apiUser = await apiClient.checkUserForSignup({ authUid: user.uid });
          let accountData;
          if (apiUser) {
            console.log(apiUser.id);
            accountData = await apiClient.bootstrap(apiUser.id);
          } else {
            // register user
            accountData = await apiClient.createUser({ authUid: user.uid, email: user.email });
          }
          createUserSession(user, accountData.user, accountData.account);
          setIsLoggedIn(true);
        }
      } else {
        console.log("signed out");
        if (!token) {
          window.location.href = process.env.REACT_APP_SIGNOUT_SUCCESS_REDIRECT_URL;
        }
      }
    });
  }

  // FIXME: this gets called every time this URL is hit even if the user is signed in
  if (token && !process.env.REACT_APP_ANONYMOUS_SIGNIN) {
    auth.signInWithCustomToken(token)
      .catch(err => {
        alert("Your session has expired.");
        window.location.href = process.env.REACT_APP_SIGNOUT_SUCCESS_REDIRECT_URL;
      });
  }

  const handleTimer = useCallback((type, values) => {
    // let entry = type === "START" || type === "STOP" ? this.props.activeEntry : values; // another type - ADD
    if (type === 'ADD') {
      addTimeEntry(values);
    }
    if (type === 'EDIT') {
      updateTimeEntry(editEntry.id, { ...values, state: editEntry.state });
    }
  }, [addTimeEntry, updateTimeEntry, editEntry]);

  return (
    <>
      <Router basename={process.env.REACT_APP_BASE_URL}>
        {user.id && <div className={classes.root}>
          <main className={classes.content}>
            <Grid container justify="center" className={classes.gutterX} spacing={0}>
              <Grid item xs={3}>
                <NavBar routes={routes} profilePicture={user && user.profilePicture ? user.profilePicture : (authUser && authUser.photoURL ? authUser.photoURL : null)} />
              </Grid>
              <Grid item xs={5} id="page-container" style={{ position: "relative" }}>
                <MuiThemeProvider theme={createMuiTheme(darkTheme)}>
                  <Switch>
                    <Redirect from="/" to="/time" exact />
                    {routes.map(route => {
                      if (route.child) {
                        return route.child.filter(routeChild => routeChild.component !== undefined).map((routeChild, index) => (
                          <Route
                            key={routeChild.label.toAppKey() + "_" + index}
                            path={routeChild.path}
                            exact={routeChild.exact}
                            component={routeChild.component}
                          />
                        ));
                      }
                      return null;
                    })}
                    <Route component={NotFound} />
                  </Switch>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </main>
        </div>}
      </Router>
      {/* Toast Notification */}
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {user.id && <>
        {projectFormDialogIsOpen ? <ProjectFormDialog /> : null}
        {clientFormDialogIsOpen ? <ClientFormDialog /> : null}
        {clientContactFormDialogIsOpen ? <ClientContactFormDialog /> : null}
        <ExpenseFormDialog />
        <TimerPopover
          key={editEntry.id || 'new'}
          type={timer.popover.entryType}
          entryId={timer.popover.entryType === 'ADD' ? undefined : editEntry.id}
          entry={timer.popover.entryType === 'ADD' ? undefined : !_.isEmpty(editEntry) ? {
            id: editEntry.id,
            project: { id: editEntry.projectId, name: editEntry.projectName },
            task: { id: editEntry.taskCategoryId, title: editEntry.taskCategoryName },
            title: editEntry.title,
            duration: editEntry.duration
          } : undefined}
          handleTimer={handleTimer}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "center", horizontal: timer.popover.transformOrigin || "left" }}
          anchorEl={timer.popover.anchorEl}
          toggleTimerPopup={toggleTimerPopup}
        />
      </>}
    </>
  );
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    background: `linear-gradient(0deg,rgba(50,66,74,0.05),rgba(50,66,74,0.05)), url(${bgImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    // padding: theme.spacing(3),
    overflowY: "auto",
    minWidth: 0 // So the Typography noWrap works
  },
  gutterX: {},
  gutterY: {
    marginTop: theme.spacing(2)
  },
  title: {
    backgroundColor: "#e6f8f9",
    padding: "15px 20px 0 20px",
    marginBottom: "25px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  row: {
    display: "flex"
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(4)
  },
  loading: {
    width: "133px",
    height: "200px",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    textAlign: "center",
    paddingTop: "13px"
  },
  contentBox: {
    padding: "0 20px 25px 20px"
  }
});

const mapStateToProps = state => {
  return {
    projectFormDialogIsOpen: state.projects.projectFormDialogIsOpen,
    authUser: state.settings.authUser,
    user: state.settings.user,
    account: state.settings.account,
    timer: state.time.timer,
    activeEntry: state.time.timer.activeEntry,
    editEntry: state.time.timer.editEntry,
    clientFormDialogIsOpen: state.clients.clientFormDialogIsOpen,
    clientContactFormDialogIsOpen: state.clients.clientContactFormDialogIsOpen
  };
};
export default withStyles(styles)(connect(mapStateToProps, { createUserSession, addTimeEntry, updateTimeEntry, toggleTimerPopup })(Main));
