import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import colors from "constants/colors";
import { CustomFormattedMessage } from "asmi-ui-web";

const NotFound = props => {
  return (
    <Paper
      style={{
        borderRadius: "unset",
        borderTopRightRadius: "10px",
        overflow: "hidden",
        height: "95vh",
        backgroundColor: colors.greyDark,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: '10px'
      }}
    >
      <Typography variant="h2">
        <CustomFormattedMessage
          textTransform="capitalize"
          id="generic.error.pageNotFoundTitle"
        />
      </Typography>
      <Typography>
        <CustomFormattedMessage
          textTransform="capitalize"
          id="generic.error.pageNotFoundMessage"
        />
      </Typography>
    </Paper>
  );
};

export default NotFound;
