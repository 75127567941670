import React from 'react';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core/styles';
import colors from "constants/colors";

const useStyles = makeStyles((theme) => ({
    loadingSpinner: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        zIndex: 1000,
        borderRadius: (props) => props.radius ? "10px" : "0 10px 10px 0",
        backgroundColor: (props) => props.light ? "#f7f8f8" : colors.greyDark
    }
})
)

const LoadingSpinner = (props) => {
    const { isLoading } = props;
    const classes = useStyles(props);
    return isLoading && (
        <div className={classes.loadingSpinner}>
            <Loader type="Oval" width={30} height={30} color="lightgray" />
        </div>
    )

}

LoadingSpinner.defaultProps = {
    isLoading: true,
    radius: false
}

export default LoadingSpinner;
