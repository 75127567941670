import {
    FETCH_INVOICESSHEET
} from "../actions/invoices";


const initState = {
    data: { overall_stats: {}, invoices: [] },
};



export default (state = initState, action) => {
    switch (action.type) {

        case FETCH_INVOICESSHEET:
            return { ...state, ...action.value };

        default:
            return state;
    }
};
