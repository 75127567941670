import * as apiClient from 'asmi-api-client-js';

export const MARKETINGMATERIAL = "MARKETINGMATERIAL";

export const marketingMaterial = (marketingMaterial) => async (dispatch, getState) => {
  dispatch({
    type: MARKETINGMATERIAL,
    value: marketingMaterial
  });

};