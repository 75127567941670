import moment from "moment";
import {
  FETCH_TIMESHEET,
  TIMESHEET_EXCEL_CSV,
  SET_TIMER_STATE,
  SET_ACTIVE_TIMER_STATE,
  SAVE_TIMER_ENTRY,
  INCREASE_DURATION,
  DELETE_TIME_ENTRY,
  TOGGLE_TIMER_POPOVER,
  LOADED_ACTIVE_TIMER_ENTRY,
  UPDATING_TIME_ENTRY,
  UPDATED_TIME_ENTRY,
  CHANGE_TODAY,
  FETCH_TIMESHEET_LOADING
} from "../actions/time";

const initState = {
  meta: {},
  data: {
    overall_stats: {}, projects: [], filters: {
      grouped: true,
      startDate: moment(),
      selectedDateRangeOption: 'today'
    }
  },
  updatingEntries: {},
  loadingTimeEntries: false,
  todayMoment: moment(),
  timer: {
    popover: {
      anchorEl: undefined,
      entryType: 'ADD'
    },
    running: false,
    activeEntry: {},
    editEntry: {},
    // using this flag to hide play button in timesheet until active timer loads
    loadingActiveEntry: true,
    lastDurationTime: undefined,
  },
  timesheetExcelCSV: []
};

export default (state = initState, action) => {
  switch (action.type) {

    case FETCH_TIMESHEET:
      return { ...state, ...action.value, loadingTimeEntries: false };

    case FETCH_TIMESHEET_LOADING:
      return { ...state, loadingTimeEntries: true }

    case TIMESHEET_EXCEL_CSV: {
      return { ...state, timesheetExcelCSV: action.value }
    }

    case SET_ACTIVE_TIMER_STATE: {
      return { ...state, timer: { ...state.timer, activeEntry: { ...action.value }, lastDurationTime: new Date() } };
    }

    case UPDATING_TIME_ENTRY: {
      return { ...state, updatingEntries: { ...state.updatingEntries, [action.value.id]: action.value } };
    }

    case UPDATED_TIME_ENTRY: {
      return { ...state, updatingEntries: { ...state.updatingEntries, [action.value.id]: undefined } }
    }

    case INCREASE_DURATION: {
      let currentTask = { ...state.timer.activeEntry };
      const increment = action.value.increment;

      const currentTime = new Date();
      const newDurationIncrement = state.timer.lastDurationTime ? (currentTime.getTime() - state.timer.lastDurationTime.getTime()) / 1000 + increment - 1 : increment;
      const newDuration = currentTask.duration + newDurationIncrement;
      if (currentTask) {
        currentTask.duration = newDuration;
      }

      let matched = false;
      let newProjects = state.data.projects.map((project) => {
        let newTasks = [];
        let projectMatched = false;
        project.tasks.forEach((task) => {
          if (task.id === currentTask.id) {
            matched = true;
            projectMatched = true;
            return newTasks.push({ ...task, duration: newDuration })
          }
          newTasks.push(task);
        })
        return { ...project, duration: projectMatched ? project.duration + newDurationIncrement : project.duration, tasks: newTasks }
      })

      return {
        ...state,
        data: { ...state.data, overall_stats: { ...state.data.overall_stats, duration: matched ? state.data.overall_stats.duration + increment : state.data.overall_stats.duration }, projects: newProjects },
        timer: { ...state.timer, activeEntry: currentTask, lastDurationTime: currentTime }
      }
    }

    case SAVE_TIMER_ENTRY: {
      return { ...state, timer: { ...state.timer, activeEntry: { ...state.timer.activeEntry, state: 'stopped' }, lastDurationTime: undefined, loadingActiveEntry: false } };
    }

    case LOADED_ACTIVE_TIMER_ENTRY: {
      return { ...state, timer: { ...state.timer, loadingActiveEntry: false } }
    }

    case DELETE_TIME_ENTRY: {
      const { projectId, taskId } = action.value;
      const projects = state.data.projects.map(project => {
        if (project.id === projectId) {
          return {
            ...project,
            tasks: project.tasks.filter(task => task.id !== taskId)
          }
        }
        return project;
      });
      return { ...state, data: { ...state.data, projects } };
    }

    case TOGGLE_TIMER_POPOVER: {
      return { ...state, timer: { ...state.timer, editEntry: { ...action.task }, popover: { ...action.value } } };
    }

    case CHANGE_TODAY: {
      if (!state.todayMoment.isSame(new Date(), 'days')) {
        let newState = { ...state, todayMoment: moment() };
        if (state.timer.activeEntry.state && state.timer.activeEntry.state !== 'running') {
          newState = { ...newState, timer: { ...state.timer, activeEntry: {} } }
        }
        return newState;
      }
      return state;
    }

    default:
      return state;
  }
};
