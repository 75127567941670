import React from "react";
import Loadable from "react-loadable";
import LoadingSpinner from "components/shared/loadingSpinner";

export const Home = Loadable({
  loader: () => import("../components/home/index"),
  loading: LoadingSpinner
});
export const Time = Loadable({
  loader: () => import("../components/time/index"),
  loading: LoadingSpinner
});
export const Projects = Loadable({
  loader: () => import("../components/projects/index"),
  loading: LoadingSpinner
});
export const Clients = Loadable({
  loader: () => import("../components/clients"),
  loading: LoadingSpinner
});
export const Team = Loadable({
  loader: () => import("../components/home/gen"),
  loading: LoadingSpinner
});
export const Estimates = Loadable({
  loader: () => import("../components/home/gen"),
  loading: LoadingSpinner
});
export const Expenses = Loadable({
  loader: () => import("../components/expenses/index"),
  loading: LoadingSpinner
});
export const Invoices = Loadable({
  loader: () => import("../components/invoices/index"),
  loading: LoadingSpinner
});
export const Reports = Loadable({
  loader: () => import("../components/home/gen"),
  loading: LoadingSpinner
});
export const Settings = Loadable({
  loader: () => import("../components/settings/Tabs"),
  loading: LoadingSpinner
});
export const Profile = Loadable({
  loader: () => import("../components/Profile/Tabs"),
  loading: LoadingSpinner
});
export const Client = Loadable({
  loader: () => import("../components/home/gen"),
  loading: LoadingSpinner
});
export const Invoice = Loadable({
  loader: () => import("../components/home/gen"),
  loading: LoadingSpinner
});
export const Signout = Loadable({
  loader: () => import("../components/signout/index"),
  loading: LoadingSpinner
});
