import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";

const ArchiveContactFormDialog = (props) => {
    const { archive, archiveIsVisible, title, onCancel, onArchive } = props;
    return (
        <Dialog
            open={archiveIsVisible}
            onClose={onCancel}
            fullWidth
            maxWidth="sm"
            aria-labelledby="Archive"
            aria-describedby="Archive-client"
        >
            <DialogTitle id="alert-dialog-title">Archive {title} ?</DialogTitle>
            <DialogContent>
                <DialogContentText>{`${archive && archive.name} will be moved to Archive !!!`}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onCancel}>
                    Cancel
          </Button>
                <Button color="secondary" onClick={onArchive} >
                    Archive
          </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ArchiveContactFormDialog;