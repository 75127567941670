export const CLIENTDATA = "CLIENTDATA";

const data = {
    data:{
        clients: [
            {
                id: 1,
                name: "Ashok Verma",
                capacity: "40",
                capacityAllocated:"40",
                projects:"2"
            },
            {
                id: 1,
                name: "mahesh j",
                capacity: "40",
                capacityAllocated:"30",
                projects:"2"
            },
            {
                id: 1,
                name: "Ashok Verma",
                capacity: "40",
                capacityAllocated:"20",
                projects:"2"
            },
            {
                id: 1,
                name: "mahesh j",
                capacity: "40",
                capacityAllocated:"40",
                projects:"2"
            },
        ]
    }
    
};

export const clientData = () => {
    return {
        type: CLIENTDATA,
        value: { ...data }
    };
};
