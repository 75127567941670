import {
    FETCH_CLIENTSHEET,
    TOGGLE_CLIENT_FORM_DIALOG,
    TOGGLE_CLIENT_CONTACT_FORM_DIALOG,
    EDIT_CLIENT
} from "actions/clients";



const initState = {
    clients: [],
    clientFormDialogIsOpen: false,
    clientContactFormDialogIsOpen: false,
    activeClient: false
};


export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_CLIENTSHEET:
            return { ...state, clients: action.value };
        case TOGGLE_CLIENT_FORM_DIALOG:
            return { ...state, clientFormDialogIsOpen: action.value };
        case TOGGLE_CLIENT_CONTACT_FORM_DIALOG:
            return { ...state, clientContactFormDialogIsOpen: action.value };
        case EDIT_CLIENT:
            return { ...state, activeClient: action.value };
        default:
            return state;
    }
};
