import React, { Fragment } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import moment from "moment";
import { Typography, Icon, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import WidgetContainer from "./widgetContainer";
import _ from "lodash";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { CustomFormattedMessage } from "asmi-ui-web";
import StartStop from "./startStop";
import {
  fetchActiveTimer,
  setTimerState,
  startTimer,
  stopTimer,
  toggleTimerPopup,
  changeToday
} from "actions/time";
import { connect } from "react-redux";

const styles = theme => ({
  icon: {
    display: "block",
    cursor: "pointer",
    "&:first-child": {
      marginBottom: 7,
      fontSize: "1.75rem"
    }
  },
  popover: {
    padding: 20,
    width: "350px"
  },
  title: {
    fontWeight: 700
  },
  select: {
    width: "100%"
  },
  grid: {
    padding: "10px 10px 10px 0"
  },
  rightAlign: {
    display: "flex",
    justifyContent: "flex-end"
  },
  cursor: {
    cursor: "pointer"
  },
  autocomplete: {
    paddingLeft: "10px",
    paddingTop: "3px !important",
    paddingBottom: "3px !important"
  }
});

// make it a container
// start stop button should be a separate component which accepts some props for opening, closing and update

class Timer extends React.Component {

  constructor(props) {
    super(props);
    this.addIconRef = React.createRef();
    this.widgetRef = React.createRef();
    this.startStopRef = React.createRef();
  }

  handleWidgetClick = event => {
    const { activeEntry } = this.props;
    event.stopPropagation();
    if (_.isEmpty(activeEntry)) {
      this.props.toggleTimerPopup("ADD", undefined, event.currentTarget);
      console.log("hello");
      return null;
    } else if (activeEntry.id) {
      this.props.toggleTimerPopup("EDIT", activeEntry, event.currentTarget);
    }
  };

  componentDidMount = () => {
    this.props.fetchActiveTimer();
    this.props.changeToday();
  }

  render() {
    const {
      classes,
      activeEntry,
      stopTimer,
      startTimer,
      toggleTimerPopup
    } = this.props;

    const noTimeEntry = _.isEmpty(activeEntry);

    const currentDuration = moment.duration(activeEntry.duration, "seconds").format("H:mm:ss", { trim: false, trunc: true });
    const taskSummary = `${activeEntry.projectName}, ${activeEntry.taskCategoryName}${activeEntry.title ? `, ${activeEntry.title}` : ''}`

    if (!noTimeEntry && activeEntry.state === 'running') {
      let arr = currentDuration.split(':');
      document.title = `${arr[0]}:${arr[1]} - ${taskSummary}`;
    } else {
      document.title = 'Asmi';
    }

    return (
      <WidgetContainer>
        <GlobalHotKeys keyMap={{ ADD_TIME_ENTRY: 'a', EDIT_TIME_ENTRY: 'e', START_STOP_TIME_ENTRY: 's' }} handlers={{
          ADD_TIME_ENTRY: () => this.addIconRef.current.click(),
          EDIT_TIME_ENTRY: () => this.widgetRef.current.click(),
          START_STOP_TIME_ENTRY: () => {
            if (this.startStopRef.current) {
              this.startStopRef.current.click();
            }
          },
        }} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div ref={this.widgetRef} onClick={this.handleWidgetClick}
            className={classes.cursor} style={{ width: '100%' }}>
            <Typography variant="subtitle1">
              <CustomFormattedMessage
                textTransform="capitalize"
                id="generic.timer"
              />
            </Typography>

            {noTimeEntry ? (
              <Fragment>
                <Typography variant="body2">
                  <FormattedMessage id="widgets.timer.emptyStateMsg" />
                </Typography>
                <Typography variant="caption">
                  <FormattedHTMLMessage id="widgets.timer.emptyStateGreeting" />
                </Typography>
              </Fragment>
            ) : (
                <Fragment>
                  <Typography variant="body2">
                    {currentDuration}
                  </Typography>
                  <Typography variant="caption">{taskSummary}</Typography>
                </Fragment>
              )}
          </div>
          <div align="center">
            {activeEntry.id !== 'new' ? <>
              <Icon
                ref={this.addIconRef}
                className={classes.icon}
                fontSize="small"
                onClick={e => {
                  toggleTimerPopup("ADD", undefined, this.widgetRef.current);
                }}
              >
                add
              </Icon>
              <StartStop
                ref={this.startStopRef}
                key={activeEntry.state}
                onClick={e => {
                  if (_.isEmpty(activeEntry)) {
                    return this.handleWidgetClick(e);
                  }
                  if (activeEntry.state === 'stopped') {
                    return startTimer(activeEntry);
                  }
                  if (activeEntry.state === 'running') {
                    return stopTimer(activeEntry);
                  }
                }}
                running={_.isEmpty(activeEntry) ? false : activeEntry.state === 'running'}
              />
            </> : <CircularProgress size={25} color='secondary' />}
          </div>
        </div>
      </WidgetContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    running: state.time.timer.running,
    activeEntry: state.time.timer.activeEntry
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    fetchActiveTimer,
    setTimerState,
    stopTimer,
    startTimer,
    toggleTimerPopup,
    changeToday
  })(Timer)
);
