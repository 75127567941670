import React from "react";
import { FormLabel } from "@material-ui/core";

export default function ErrorLabel(props) {
  return (
    <FormLabel style={{ color: "red", fontSize: "1rem" }}>
      {props.children}
    </FormLabel>
  );
}
