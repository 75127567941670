
export const COMPANYNAME = 'COMPANYNAME';
export const TIMEZONE = 'TIMEZONE';
export const WEEKDAY = 'WEEKDAY';
export const DATEFORMAT = 'DATEFORMAT';
export const TIMEFORMAT = 'TIMEFORMAT';
export const TIMEROUNDING = 'TIMEROUNDING';
export const NUMBERFORMAT = 'NUMBERFORMAT';
export const CURRENCIES = 'CURRENCIES';

export const companyName = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : COMPANYNAME,
        value: event
    })
}

export const timeZone = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : TIMEZONE,
        value: event
    })
}
export const weekDay = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : WEEKDAY,
        value: event
    })
}
export const dateFormat = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : DATEFORMAT,
        value: event
    })
}

export const timeFormat = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : TIMEFORMAT,
        value: event
    })
}
export const timeRounding = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : TIMEROUNDING,
        value: event
    })
}
export const numberFormat = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : NUMBERFORMAT,
        value: event
    })
}
export const currenciesData = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : CURRENCIES,
        value: event
    })
}
