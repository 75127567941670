import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import { theme } from 'asmi-ui-web';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import store, { history } from './store';
import { ConnectedRouter } from 'connected-react-router';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import * as apiClient from 'asmi-api-client-js';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';

Sentry.init({
  dsn: "https://e31736e6c11c475e81642caa9f26f2f5@o443018.ingest.sentry.io/5415894"
});

momentDurationFormatSetup(moment);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

//init firebase and apiClient
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().enablePersistence()
.catch(function (err) {
  if (err.code === 'failed-precondition') {
    console.log(err);
  } else if (err.code === 'unimplemented') {
    console.log(err);
  }
});
apiClient.init(firebase);

const target = document.querySelector('#root');
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
  , target
);
