import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "constants/colors";
import { CustomFormattedMessage } from "asmi-ui-web";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: `${theme.spacing(2)}px`,
    textAlign: "center",
    borderBottom: `1px solid ${colors.divider}`,
    borderRight: `1px solid ${colors.divider}`,
    color: theme.palette.text.secondary,
    fontWeight: 700,
    height: "4.5rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.greyLight,
      color: `${colors.logoColor} !important`
    }
  },
  subNavPaper: {
    padding: `${theme.spacing(2)}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderBottom: `1px solid ${colors.divider}`,
    borderRight: `1px solid ${colors.divider}`,
    fontWeight: 600,
    cursor: "pointer",
    fontSize: `1rem`,
    "&:hover": {
      color: `${colors.logoColor}`,
      fontWeight: 700
    }
  },
  paddingTop: {
    paddingTop: `${theme.spacing(1.25)}px`
  },
  active: {
    backgroundColor: colors.greyLight,
    borderBottom: `3px solid ${colors.secondary.main}`
  },
  childActive: {
    fontWeight: 700,
    color: `${colors.logoColor} !important`,
    borderBottom: `2px solid ${colors.secondary.main}`
  },
  linkText: {
    fontSize: `1rem`,
    textDecoration: "none",
    color: colors.text.primary,
    cursor: "pointer",
    fontWeight: 700,
    "&:hover": {
      color: `${colors.logoColor}`
    }
  }
}));

export function NavLinkElement(props) {
  const classes = useStyles();
  const { route } = props;
  if (route.url) {
    return <a id={route.id} href={route.url} target="_blank" className={classes.linkText}>
      <CustomFormattedMessage textTransform="capitalize" id={route.label} />
    </a>
  }
  return (
    <NavLink
      to={route.path}
      className={classes.linkText}
      activeClassName={classes.childActive}
      exact={route.exact}
    >
      <CustomFormattedMessage textTransform="capitalize" id={route.label} />
    </NavLink>
  );
}

export function NavElementContainer({ active, route, compact, ...props }) {
  const classes = useStyles();

  return (
    <Grid item xs={4} {...props}>
      <div className={`${classes.paper} ${compact ? classes.paddingTop : ""} ${active ? classes.active : ""} ${route && route.className ? route.className : ""}`}>
        {route || compact ? (
          props.children
        ) : (
            <Typography
              className={classes.linkText}
              style={{ paddingTop: "3px" }}
            >
              {props.children}
            </Typography>
          )}
      </div>
    </Grid>
  );
}

NavElementContainer.propTypes = {
  active: PropTypes.bool,
  compact: PropTypes.bool,
  onClick: PropTypes.func
};

export function NavElement(props) {
  return (
    <NavElementContainer {...props}>
      <NavLinkElement {...props}></NavLinkElement>
    </NavElementContainer>
  );
}

export function SubNavElement(props) {
  const classes = useStyles();
  return (
    <Grid item xs={6} {...props}>
      <div className={classes.subNavPaper}>
        <a
          href={props.element.link}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {props.element.label}
        </a>
      </div>
    </Grid>
  );
}

export function DialogPupup(props) {
  const classes = useStyles();
  return (
    <NavElementContainer {...props}>
      <span className={classes.linkText} >{props.children}</span>
    </NavElementContainer>
  );
}
