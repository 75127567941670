import * as apiClient from 'asmi-api-client-js';

export const FETCH_PROJECTSHEET = "FETCH_PROJECTSHEET";
export const FETCH_PROJECT_TASK_CATEGORIES = "FETCH_PROJECT_TASK_CATEGORIES";
export const TOGGLE_PROJECT_FORM_DIALOG = "TOGGLE_PROJECT_FORM_DIALOG";
export const EDIT_PROJECT = "EDIT_PROJECT";

// dispatch fetchTimeSheet with data from backend
export const fetchProjectSheet = (projects) => async (dispatch, getState) => {
  let apiProjects = [];
  const userId = getState().settings.user.id;
  if (!userId) {
    return;
  }
  if (projects) {
    return dispatch({
      type: FETCH_PROJECTSHEET,
      value: projects.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))
    });
  }
  if (getState().projects.projects.length === 0) {
    apiProjects = (await apiClient.getAllProjects(userId)).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
    dispatch({
      type: FETCH_PROJECTSHEET,
      value: apiProjects
    });
  }
};

export const fetchProjectTaskCategories = projectId => async (dispatch, getState) => {
  let pTaskCategories = getState().projects.taskCategories[projectId];
  const userId = getState().settings.user.id;
  if (!pTaskCategories || pTaskCategories.length === 0) {
    const apiProjectTaskCategories = await apiClient.getAllProjectTaskCategories(userId, projectId);
    return dispatch({
      type: FETCH_PROJECT_TASK_CATEGORIES,
      value: {
        projectId,
        data: apiProjectTaskCategories
      }
    });
  }
}

// ProjectFormDialogIsOpen
export const toggleProjectFormDialog = (Bool) => {
  return {
    type: TOGGLE_PROJECT_FORM_DIALOG,
    value: Bool
  };
};

// Edit Project
export const editProject = (project) => {
  return {
    type: EDIT_PROJECT,
    value: project
  };
};
