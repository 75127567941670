import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import * as apiClient from 'asmi-api-client-js';
import { Formik, FieldArray } from 'formik';
import * as Yup from "yup";
import LoadingSpinner from "components/shared/loadingSpinner";
import { toggleClientContactFormDialog, editClient } from "actions/clients";
import { toast } from "react-toastify";
import ArchiveContactFormDialog from "components/clients/forms/archiveContactFormDialog"
import { FormattedList } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(3),
  },
  listItem: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  listItemPrimary: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1.125rem",
    marginBottom: 5,
    color: "#4f5f63"
  },
  listItemSecondary: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1rem",
    marginBottom: 5,
    color: "rgba(50,66,74, 0.65)",
  },

}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({
  clientContacts: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().min(2, 'Too short!').max(50, 'Too long!').required('First name is required'),
        lastName: Yup.string().max(50, 'Too long!').required('Last name is required'),
        email: Yup.string().email("Should be an email").required('Email is required'),
      })
    )
});


const ClientForm = (props) => {
  const classes = useStyles();
  const {
    user,
    activeClient,
    clientContactFormDialogIsOpen,
    toggleClientContactFormDialog,
    editClient
  } = props;
  const newContactISadded = useRef(false);
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [activeContactIndex, setActiveContactIndex] = useState(null);
  const [initialValues, setInitialValues] = useState({ clientContacts: [] });
  const [archiveIsVisible, setArchiveIsVisible] = useState(false);
  const [archive, setArchive] = useState(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (activeClient) {
      setIsLoading(true)
      apiClient.getAllClientContacts(activeClient.userId, activeClient.clientId)
        .then((clientContacts) => {
          newContactISadded.current = false;
          if (isMounted.current) {
            setInitialValues({ clientContacts });
            setIsLoading(false);
          }

        })
        .catch(error => {
          if (isMounted.current) {
            toast(error.message);
            setIsLoading(false);
          }
        })
    }
    return () => {
      editClient(false);
    }
  }, [activeClient])


  //handle close
  const onCloseHandler = useCallback(() => {
    toggleClientContactFormDialog(false);
  }, []);

  //edit client contact
  const editClientContact = useCallback((arrayHelpers, formik, index) => {
    if (newContactISadded.current) {
      arrayHelpers.pop();
      newContactISadded.current = false;
      setActiveContactIndex(index);
    } else {
      formik.setFieldValue(`clientContacts.${activeContactIndex}`, initialValues.clientContacts[activeContactIndex])
      setActiveContactIndex(index);
    }
  }, []);


  //add new Client  contact
  const addNewClientContact = useCallback((arrayHelpers, formik) => {
    if (!newContactISadded.current) {
      newContactISadded.current = true;
      setActiveContactIndex(formik.values.clientContacts.length)
      arrayHelpers.push({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: ""
      })
    }
  }, []);

  //cancel Client contact edit
  const cancelClientContactEdit = useCallback((arrayHelpers, formik) => {
    if (newContactISadded.current) {
      arrayHelpers.pop();
      newContactISadded.current = false;
      setActiveContactIndex(null);
    } else {
      formik.setFieldValue(`clientContacts.${activeContactIndex}`, initialValues.clientContacts[activeContactIndex])
      setActiveContactIndex(null);
    }
  }, []);

  //archive contact
  const archiveContactHandler = useCallback((archive, arrayHelpers) => {
    setArchiveIsVisible(false);
    setIsLoading(true);
    if (newContactISadded.current) {
      arrayHelpers.pop();
      newContactISadded.current = false;
    }
    apiClient.archiveClientContact(archive.userId, archive.contactId)
      .then((response => {
        if (isMounted.current) {
          setInitialValues((initialValues) => {
            let newValue = { ...initialValues, clientContacts: [...initialValues.clientContacts] };
            newValue.clientContacts.splice(archive.index, 1);
            return newValue;
          });
          setActiveContactIndex(null)
          setIsLoading(false);
          setArchive(null);
        }

      }))
      .catch(error => {
        if (isMounted.current) {
          toast(error.message);
          setArchiveIsVisible(true);
          setIsLoading(false);
        }
      })
  }, [])

  return (
    <Dialog
      open={clientContactFormDialogIsOpen}
      onClose={onCloseHandler}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, formikBag) => {
          if (activeClient) {
            setIsLoading(true);
            let activeContact = values.clientContacts[activeContactIndex];
            if (activeContact.id) {
              apiClient.updateClientContact(activeClient.userId, activeContact.id, activeContact)
                .then(contact => {
                  if (isMounted.current) {
                    setInitialValues((initialValues) => {
                      let newValue = { ...initialValues, clientContacts: [...initialValues.clientContacts] };
                      newValue.clientContacts[activeContactIndex] = contact;
                      return newValue;
                    });
                    setActiveContactIndex(null)
                    formikBag.setSubmitting(false);
                    setIsLoading(false);
                  }
                })
                .catch(error => {
                  if (isMounted.current) {
                    toast(error.message)
                    formikBag.setSubmitting(false);
                    setIsLoading(false);
                  }
                })
            } else {
              apiClient.addClientContact(activeClient.userId, activeClient.clientId, activeContact)
                .then(response => {
                  if (isMounted.current) {
                    setInitialValues((initialValues) => {
                      let newValue = { ...initialValues, clientContacts: [...initialValues.clientContacts] };
                      newValue.clientContacts[activeContactIndex] = response.contact;
                      return newValue;
                    });
                    newContactISadded.current = false;
                    setActiveContactIndex(null);
                    formikBag.setSubmitting(false);
                    setIsLoading(false);
                  }

                })
                .catch(error => {
                  if (isMounted.current) {
                    toast(error.message)
                    setIsLoading(false);
                    formikBag.setSubmitting(false);
                  }
                })
            }
          }
        }}
      >
        {(formik) => (
          <>
            <LoadingSpinner isLoading={isLoading} radius={true} light={true} />
            <div className={classes.headerTitle} >
              <Typography variant="h1">Client Contacts</Typography>
              <IconButton onClick={onCloseHandler}>
                <CancelIcon color="secondary" />
              </IconButton>
            </div>
            <Divider />

            <FieldArray
              name="clientContacts"
              render={arrayHelpers => (
                <>
                  <DialogContent>
                    <List>
                      {formik.values.clientContacts.map((contact, index) => {
                        return (
                          <div key={`${index}`}>
                            {!(activeContactIndex === index) ? (
                              <ListItem className={classes.listItem} button onClick={() => editClientContact(arrayHelpers, formik, index)} disableGutters divider key={contact.id}>
                                <ListItemText
                                  primary={`${contact.firstName} ${contact.lastName}`} primaryTypographyProps={{ className: classes.listItemPrimary }}
                                  secondary={`${contact.email} ${contact.phone}`} secondaryTypographyProps={{ className: classes.listItemSecondary }}
                                />
                                {!contact.mailingAddress && (
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={() => {
                                      setArchive({ userId: user.id, contactId: contact.id, name: `${contact.firstName} ${contact.lastName}`, index })
                                      setArchiveIsVisible(true);
                                    }} edge="start" aria-label="delete">
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                            ) : (
                                <div>
                                  <Grid container spacing={2} >
                                    <Grid item xs={6}>
                                      <TextField
                                        label="First name"
                                        name="firstName"
                                        type="text"
                                        placeholder="First name"
                                        {...formik.getFieldProps(`clientContacts.${index}.firstName`)}
                                        helperText={
                                          formik.touched.clientContacts &&
                                          formik.touched.clientContacts[index] &&
                                          formik.touched.clientContacts[index].firstName &&
                                          formik.errors.clientContacts &&
                                          formik.errors.clientContacts[index] &&
                                          formik.errors.clientContacts[index].firstName
                                        }
                                        error={
                                          formik.touched.clientContacts &&
                                            formik.touched.clientContacts[index] &&
                                            formik.touched.clientContacts[index].firstName &&
                                            formik.errors.clientContacts &&
                                            formik.errors.clientContacts[index] &&
                                            formik.errors.clientContacts[index].firstName ? true : false
                                        }
                                        variant="filled"
                                        fullWidth
                                      />
                                    </Grid>

                                    <Grid item xs={6}>
                                      <TextField
                                        label="Last name"
                                        name="lastName"
                                        type="text"
                                        placeholder="Last name"
                                        {...formik.getFieldProps(`clientContacts.${index}.lastName`)}
                                        helperText={
                                          formik.touched.clientContacts &&
                                          formik.touched.clientContacts[index] &&
                                          formik.touched.clientContacts[index].lastName &&
                                          formik.errors.clientContacts &&
                                          formik.errors.clientContacts[index] &&
                                          formik.errors.clientContacts[index].lastName
                                        }
                                        error={
                                          formik.touched.clientContacts &&
                                            formik.touched.clientContacts[index] &&
                                            formik.touched.clientContacts[index].lastName &&
                                            formik.errors.clientContacts &&
                                            formik.errors.clientContacts[index] &&
                                            formik.errors.clientContacts[index].lastName ? true : false
                                        }
                                        variant="filled"
                                        fullWidth
                                      />
                                    </Grid>

                                    <Grid item xs={6}>
                                      <TextField
                                        label="Email address"
                                        name="email"
                                        type="email"
                                        placeholder="Email address"
                                        {...formik.getFieldProps(`clientContacts.${index}.email`)}
                                        helperText={
                                          formik.touched.clientContacts &&
                                          formik.touched.clientContacts[index] &&
                                          formik.touched.clientContacts[index].email &&
                                          formik.errors.clientContacts &&
                                          formik.errors.clientContacts[index] &&
                                          formik.errors.clientContacts[index].email
                                        }
                                        error={
                                          formik.touched.clientContacts &&
                                            formik.touched.clientContacts[index] &&
                                            formik.touched.clientContacts[index].email &&
                                            formik.errors.clientContacts &&
                                            formik.errors.clientContacts[index] &&
                                            formik.errors.clientContacts[index].email ? true : false
                                        }
                                        variant="filled"
                                        fullWidth
                                      />
                                    </Grid>


                                    <Grid item xs={6}>
                                      <TextField
                                        label="Phone"
                                        name="phone"
                                        type="tel"
                                        placeholder="Phone"
                                        value={formik.values.clientContacts[index].phone}
                                        onChange={(event) => {
                                          let valid = /^\+?\d{0,15}$/.test(event.target.value);
                                          if (valid) formik.setFieldValue(`clientContacts.${index}.phone`, event.target.value);
                                        }}
                                        helperText={""}
                                        variant="filled"
                                        fullWidth
                                      />
                                    </Grid>

                                    <Grid item xs={12}>
                                      <TextField
                                        label="Notes"
                                        name="notes"
                                        type="text"
                                        placeholder="Notes"
                                        {...formik.getFieldProps(`clientContacts.${index}.notes`)}
                                        helperText={""}
                                        variant="filled"
                                        fullWidth
                                      />
                                    </Grid>

                                    <Grid container justify="flex-end" item xs={12}>
                                      <Button onClick={() => cancelClientContactEdit(arrayHelpers, formik)} style={{ marginLeft: "8px" }} size="small" variant="outlined">
                                        CANCEL
                                       </Button>
                                      <Button onClick={formik.submitForm} style={{ marginLeft: "8px" }} size="small" variant="contained">
                                        SAVE
                                       </Button>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                          </div>
                        )
                      })}
                    </List>

                  </DialogContent>
                  <DialogActions>
                    <Button disabled={newContactISadded.current} onClick={() => addNewClientContact(arrayHelpers, formik)} variant="contained">
                      ADD CONTACT
                     </Button>
                  </DialogActions>
                  <ArchiveContactFormDialog
                    title="Contact"
                    archive={archive}
                    archiveIsVisible={archiveIsVisible}
                    onArchive={() => archiveContactHandler(archive, arrayHelpers)}
                    onCancel={() => {
                      setArchiveIsVisible(false);
                      setArchive(null);
                    }}
                  />
                </>
              )}
            />
          </>
        )}
      </Formik>

    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    user: state.settings.user,
    account: state.settings.account,
    activeClient: state.clients.activeClient,
    clientContactFormDialogIsOpen: state.clients.clientContactFormDialogIsOpen,
  };
};

export default connect(mapStateToProps, { toggleClientContactFormDialog, editClient })(ClientForm);

// id: "R8HVhKsaQeAnZh0jZfQ9"
// clientId: "cFQu91w1cLlfgzgeW9rL"
// firstName: "Evergreen"
// lastName: "George"
// email: "admin@georgeevergreen.com"
// phone: 8161272805
// notes: "Test for client contact"
// isArchived: false
