// import axios from "axios/index";
// import constants from "../constants/global";

// eslint-disable-next-line
String.prototype.toJSON = function() {
  return JSON.parse(this);
};
// eslint-disable-next-line
String.prototype.toAppKey = function() {
  return this.toLowerCase()
    .trim()
    .replace(/([a-z\d])([A-Z]+)/g, "$1_$2")
    .replace(/[-\s]+/g, "_");
};

/* axios.defaults.headers.common["APITOKEN"] = process.env.REACT_APP_STAGING_TOKEN;

axios.interceptors.response.use(undefined, error => {
  console.log("error called axios");
  const status = error.response ? error.response.status : null;
  if (status === 403) {
    console.log(constants);
    return window.open(constants.URL.login, "_self");
  }
  return Promise.reject(error);
}); */
