import React from "react";
import colors from "constants/colors";

export default function WidgetContainer({ children, ...props }) {
  return (
    <div
      style={{
        padding: "1rem",
        paddingBottom: "1rem",
        backgroundColor: colors.grey,
        borderTop: `1px solid ${colors.divider}`,
        borderBottom: `1px solid ${colors.divider}`
      }}
      {...props}
    >
      {children}
    </div>
  );
}
