import React from "react";
import { Typography } from "@material-ui/core";
import WidgetContainer from "./widgetContainer";
import colors from "constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  linkText: {
    textDecoration: "underline",
    cursor: "pointer",
    color: "inherit",
    "&:hover": { color: colors.logoColor, fontWeight: 500 }
  }
}));

export function BlogLink(props) {
  const classes = useStyles();
  return (
    <WidgetContainer>
      <Typography variant="subtitle1">
        <FormattedMessage id="widgets.blog.heading" />
      </Typography>
      <a className={classes.linkText} href="/">
        10 ways to bring more balance to your freelance life
      </a>
    </WidgetContainer>
  );
}

export function GetMoreFromAsmi(props) {
  const classes = useStyles();
  return (
    <WidgetContainer>
      <Typography variant="subtitle1">
        <FormattedMessage id="widgets.getmorefromAsmi.heading" />
      </Typography>
      <Typography variant="body2">
        <a className={classes.linkText} href="/">
          Upgrade now
        </a>{" "}
        for a streamlined freelance business
      </Typography>
    </WidgetContainer>
  );
}
