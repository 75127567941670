import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import apiLoading from "./apiLoading";
import urlLoading from "./urlLoading";
import time from "./time";
import projects from "./projects";
import clients from "./clients";
import invoices from "./invoices";
import expenses from "./expenses";
import settingTeam from "./settingTeam"

import settings from "./settings";
import settingAccount from "./settingAccount"
import settingInvoices from "./settingInvoices"
import profileNotifications from "./profileNotifications"


export default history =>
  combineReducers({
    router: connectRouter(history),
    apiLoading,
    urlLoading,
    time,
    projects,
    clients,
    invoices,
    expenses,
    settingTeam,
    settings,
    settingAccount,
    settingInvoices,
    profileNotifications
  });
