import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";

import {
  Typography,
  Popover,
  Button,
  Grid,
  TextField
} from "@material-ui/core";

import { Formik, Form } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ErrorLabel from "components/shared/ErrorLabel";
import * as Yup from "yup";
import * as moment from "moment";
import { FormattedMessage } from "react-intl";
import { CustomFormattedMessage } from "asmi-ui-web";
import { withStyles } from "@material-ui/core/styles";
import {
  fetchProjectSheet,
  fetchProjectTaskCategories
} from 'actions/projects';
import {
  fetchTaskCategories
} from 'actions/settings';

const requiredError = (
  <CustomFormattedMessage
    textTransform="capitalize"
    id="generic.form.error.required"
  />
);

const styles = theme => ({
  popover: {
    width: "350px"
  }
});

const TimerPopover = ({
  classes,
  type,
  margin,
  anchorEl,
  toggleTimerPopup,
  transformOrigin,
  anchorOrigin,
  handleTimer,
  entry,
  entryId,
  projectTaskCategories,
  fetchProjectSheet,
  fetchTaskCategories,
  fetchProjectTaskCategories,
  ...props
}) => {
  const open = Boolean(anchorEl)
  const id = open ? "add-timer-popover" : undefined;

  const [project, setProject] = useState(entry ? entry.project : null);
  const [projects, setProjects] = useState(props.projects);
  const [task, setTask] = useState(entry ? entry.task : null);
  const [taskCategories, setTaskCategories] = useState(props.taskCategories);
  const [showDurationLabel, setShowDurationLabel] = useState(false);
  const [durationEdited, setDurationEdited] = useState(false);
  const [duration, setDuration] = useState(entry ? entry.duration : undefined);
  const projectTextInput = useRef(null);
  const taskInput = useRef();
  const titleInput = useRef();

  const validationSchema = useMemo(() => Yup.object().shape({
    type: Yup.string(),
    project: Yup.string().min(2, requiredError).required(requiredError).nullable(),
    task: Yup.string().min(2, requiredError).required(requiredError).nullable(),
    duration: Yup.string().when('type', {
      is: type => type !== 'ADD',
      then: Yup.string().required(requiredError),
      otherwise: Yup.string()
    })
  }), []);

  useEffect(() => {
    fetchProjectSheet();
    fetchTaskCategories();
  }, [fetchProjectSheet, fetchTaskCategories]);

  useEffect(() => {
    if (project) {
      fetchProjectTaskCategories(project.id);
    }
  }, [fetchProjectTaskCategories, project]);

  useEffect(() => {
    setDurationEdited(false);
    setProject(entry && entry.project ? entry.project : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryId])

  useEffect(() => {
    if (task && props.taskCategories.findIndex(tc => tc.id === task.id) === -1) {
      setTaskCategories([task, ...props.taskCategories]);
    } else {
      setTaskCategories(props.taskCategories);
    }
  }, [props.taskCategories, task]);

  useEffect(() => {
    if (project && props.projects.findIndex(proj => proj.id === project.id) === -1) {
      setProjects([project, ...props.projects]);
    } else {
      setProjects(props.projects);
    }
  }, [props.projects, project]);

  function handleClose(event) {
    setTask(null);
    setProject(null);
    setShowDurationLabel(false);
    setDuration(undefined);
    if (event) {
      event.stopPropagation();
    }
    toggleTimerPopup("ADD", undefined);
  }

  const handleSubmit = ({ project, task, title, duration: formDuration }, { setSubmitting }) => {
    const finalDuration = duration ? duration : formDuration;
    handleTimer(type, {
      projectId: project.id,
      projectName: project.name,
      taskCategoryId: task.id,
      taskCategoryName: task.title,
      title,
      duration: finalDuration ? finalDuration.indexOf && finalDuration.indexOf(':') ? moment.duration(finalDuration).asSeconds() : parseFloat(finalDuration) : 0,
      durationEdited
    });
    handleClose();
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onEntered={e => {
        const input = projectTextInput.current.getElementsByTagName('input');
        if (input.length > 0) {
          input[0].focus();
        }
      }}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Grid container direction={"column"} className={classes.popover}>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"}>
            {type === "ADD" ? (
              <FormattedMessage id="widgets.timer.newTimer" />
            ) : (
                <FormattedMessage id="widgets.timer.editTimer" />
              )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            key={`${entryId}-${projects.length}`}
            initialValues={entry ? {
              project: projects.find(p => p.id === entry.project.id),
              task: taskCategories.find(t => t.id === entry.task.id),
              title: entry.title || '',
              duration: moment.duration(entry.duration, "seconds").format("H:mm", { trim: false, trunc: true }),
              type
            } : { project, task, duration, type }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting
            }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container item>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="project"
                        name="project"
                        value={values.project}
                        onChange={(event, value) => {
                          setProject(value);

                          // reset task
                          setFieldValue('task', null);
                          setTask(null);
                          taskInput.current.getElementsByTagName('input')[0].focus();
                          return handleChange({
                            target: { name: "project", value }
                          });
                        }}
                        onBlur={handleBlur}
                        options={projects.sort((a, b) => a.clientName ? a.clientName.localeCompare(b.clientName) : 1)}
                        getOptionLabel={option => option.name}
                        groupBy={option => option.clientName}
                        renderInput={params => (
                          <TextField {...params} label="Project" fullWidth ref={projectTextInput} />
                        )}
                        openOnFocus
                      />
                      {errors.project && touched.project ? (
                        <ErrorLabel>{errors.project}</ErrorLabel>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="task"
                        name="task"
                        ref={taskInput}
                        value={values.task}
                        onChange={(event, value) => {
                          setTask(value);
                          titleInput.current.getElementsByTagName('input')[0].focus();
                          return handleChange({
                            target: { name: "task", value }
                          })
                        }}
                        onBlur={handleBlur}
                        options={values.project && taskCategories.length && projectTaskCategories[values.project.id] ? taskCategories.filter(tc => projectTaskCategories[values.project.id].findIndex(ptc => ptc.taskCategoryId === tc.id) > -1) : []}
                        getOptionLabel={option => option.title}
                        renderInput={params => (
                          <TextField {...params} label="Task Category" fullWidth />
                        )}
                        openOnFocus
                      />
                      {errors.task && touched.task ? (
                        <ErrorLabel>{errors.task}</ErrorLabel>
                      ) : null}
                    </Grid>
                    <Grid item xs={9}>
                      <FormattedMessage id="widgets.timer.newTimer.titlePlaceholder">
                        {msg => (
                          <TextField
                            name="title"
                            ref={titleInput}
                            label={msg}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth={true}
                          ></TextField>
                        )}
                      </FormattedMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <FormattedMessage id="widgets.timer.newTimer.durationPlaceholder">
                        {msg => (
                          <TextField
                            name="duration"
                            label={showDurationLabel ? 'Duration' : (values.duration ? 'Duration' : '0:00')}
                            value={values.duration || ''}
                            autoComplete="false"
                            onChange={e => {
                              const value = e.currentTarget.value;
                              if (value && value.indexOf(':') === -1) {
                                setDuration(moment.duration(value, "hours").format("H:mm", { trim: false, trunc: true }));
                              } else {
                                setDuration(value);
                              }
                              setDurationEdited(true);
                              handleChange(e);
                            }}
                            onFocus={e => setShowDurationLabel(true)}
                            onBlur={e => {
                              setShowDurationLabel(false);
                              setDurationEdited(true);
                              handleBlur(e);
                              if (values.duration && values.duration.indexOf(':') === -1) {
                                setFieldValue('duration', moment.duration(values.duration, "hours").format("H:mm", { trim: false, trunc: true }));
                              }
                            }}
                            fullWidth={true}
                          ></TextField>
                        )}
                      </FormattedMessage>
                      {errors.duration && touched.duration ? (
                        <ErrorLabel>{errors.duration}</ErrorLabel>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: 'right' }}
                    >
                      <Button onClick={handleClose} variant="outlined">
                        <CustomFormattedMessage
                          textTransform="uppercase"
                          id="generic.btnCancel"
                        />
                      </Button>
                      <Button type="submit" style={{ marginLeft: 5 }}>
                        {type === "ADD" ? (
                          <CustomFormattedMessage
                            textTransform="uppercase"
                            id={values.duration ? "generic.btnAdd" : "generic.btnStart"}
                          />
                        ) : (
                            <CustomFormattedMessage
                              textTransform="uppercase"
                              id="generic.btnSave"
                            />
                          )}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
          </Formik>
        </Grid>
      </Grid>
    </Popover>
  );
};

const mapStateToProps = state => {
  return {
    projects: state.projects.projects,
    taskCategories: state.settings.taskCategories,
    projectTaskCategories: state.projects.taskCategories
  };
};

export default withStyles(styles)(connect(mapStateToProps, { fetchProjectSheet, fetchProjectTaskCategories, fetchTaskCategories })(TimerPopover));
