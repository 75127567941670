import {
  Home,
  Projects,
  Estimates,
  Clients,
  Team,
  Time,
  Expenses,
  Invoices,
  Reports,
  Settings,
  Profile,
  Client,
  Invoice,
  Signout
} from "./asyncRoutes";

export const userRoutes = [
  {
    path: "/",
    exact: true,
    label: "Home",
    child: [
      {
        path: "/time",
        exact: true,
        label: "generic.time",
        component: Time
      },
      {
        path: "/projects",
        exact: true,
        label: "generic.projects",
        component: Projects
      },
      {
        path: "/clients",
        exact: true,
        label: "generic.clients",
        component: Clients
      },
      /* {
        path: "/expenses",
        exact: true,
        label: "generic.expenses",
        component: Expenses
      }, */
      {
        id: "help",
        url: "mailto:support@asmi.io",
        exact: true,
        label: "generic.help"
      },
      {
        path: "/changelog",
        exact: true,
        label: "generic.changelog",
        className: "changelog"
      }
      /*{
        path: "/reports",
        exact: true,
        label: "generic.reports",
        component: Reports
      },*/
      /* {
        path: "/help",
        exact: true,
        label: "generic.help",
        component: Reports
      },
      {
        path: "/referral",
        exact: true,
        label: "generic.referral",
        component: Reports
      },
      {
        path: "/invoices",
        exact: true,
        label: "generic.invoices",
        component: Invoices
      }, */
    ]
  },
  {
    path: "/",
    exact: true,
    label: "Profile",
    child: [
      {
        path: "/settings",
        exact: true,
        label: "generic.settings",
        component: Settings
      },
       /* {
        path: "/profile",
        exact: true,
        label: "generic.profile",
        component: Profile
      },  */
      {
        path: "/signout",
        exact: true,
        label: "generic.signout",
        component: Signout
      }
    ]
  },
  // {
  //   path: "/",
  //   exact: true,
  //   label: "Add",
  //   child: [{
  //     path: "/",
  //     exact: true,
  //     label: "generic.project",
  //     component: Projects
  //   },
  //   {
  //     path: "/",
  //     exact: true,
  //     label: "generic.client",
  //     component: Client
  //   },
  //   {
  //     path: "/",
  //     exact: true,
  //     label: "generic.invoice",
  //     component: Invoice
  //   }]
  // }
];
