import {
  MARKETINGMATERIAL
} from "../actions/profileNotifications";


const initState = {
  marketingMaterial: false
};


export default (state = initState, action) => {
  switch (action.type) {
    case MARKETINGMATERIAL:
      return { ...state, marketingMaterial: action.value }
    default:
      return state;
  }
};