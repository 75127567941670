import {
    FETCH_PROJECTSHEET,
    FETCH_PROJECT_TASK_CATEGORIES,
    TOGGLE_PROJECT_FORM_DIALOG,
    EDIT_PROJECT
} from "../actions/projects";

const initState = {
    projects: [],
    projectFormDialogIsOpen: false,
    activeProject: false,
    taskCategories: {}
};

export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_PROJECTSHEET:
            return { ...state, projects: action.value };
        case FETCH_PROJECT_TASK_CATEGORIES:
            return { ...state, taskCategories: { ...state.taskCategories, [action.value.projectId]: action.value.data } }
        case TOGGLE_PROJECT_FORM_DIALOG:
            return { ...state, projectFormDialogIsOpen: action.value };
        case EDIT_PROJECT:
            return { ...state, activeProject: action.value };
        default:
            return state;
    }
};
