export const NAME = "NAME";
export const ADDRESS = "ADDRESS";
export const PAYMENTMETHOD = "PAYMENTMETHOD";
export const NOTES = "NOTES";
export const FOOTER = "FOOTER";
export const LOGO = "LOGO";
export const VAT = "VAT";
export const SHIPPING = "SHIPPING";



export const name = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : NAME,
        value: event
    })
}
export const address = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : ADDRESS,
        value: event
    })
}

export const paymentMethod = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : PAYMENTMETHOD,
        value: event
    })
}
export const notes = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : NOTES,
        value: event
    })
}
export const footer = (event) => dispatch => {
    console.log(event,"action")
    dispatch({
        type : FOOTER,
        value: event
    })
}

export const logoToggle = (bool) => dispatch => {
    console.log(bool,"action")
    dispatch({
        type : LOGO,
        value: bool
    })
}

export const vatToggle = (bool) => dispatch => {
    console.log(bool,"action")
    dispatch({
        type : VAT,
        value: bool
    })
}
export const shippingToggle = (bool) => dispatch => {
    console.log(bool,"action")
    dispatch({
        type : SHIPPING,
        value: bool
    })
}