import React, { useState } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import DatePicker from "react-datepicker";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toggleExpenseFormDialog } from "./../../../actions/expenses";



const useStyles = makeStyles((theme) => ({
    headerTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(2, 3),
    },
    headerText: {
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: 800,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.2,
        letterSpacing: "normal",
    },
    dividerMargin: {
        margin: theme.spacing(2, 0)
    },
    dialogAction: {
        padding: theme.spacing(2, 3),
    },
    dialogContentText: {
        margin: 0
    },
    list: {
        padding: theme.spacing(0, 3)
    },
    listItemPrimary: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "1.125rem",
        marginBottom: 5,
        color: "#4f5f63"
    },
    listItemSecondary: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "1rem",
        marginBottom: 5,
        color: "rgba(50,66,74, 0.65)",
    },
}))



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomInput = (props) => {
    return <TextField
        label={props.placeholder}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onClick={props.onClick}
        type="text"
        helperText={""}
        variant="filled"
        fullWidth
    />
};




const ExpenceForm = (props) => {
    const { data, expenseFormDialogIsOpen, toggleExpenseFormDialog } = props;
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            "project": "",
            "start-date": null,
            "amount": "",
            "type": "",
            "status": "",
            "settled-date": null,
        },
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
            //onsubit handler
        },
    });

    const setFormField = (field, value) => {
        formik.setFieldValue(field, value);
    }

    //handle close
    const onCloseHandler = () => {
        toggleExpenseFormDialog(false);
        formik.resetForm()
    }

    return (
        <Dialog
            open={expenseFormDialogIsOpen}
            onClose={onCloseHandler}
            fullWidth
            maxWidth="sm"
            PaperProps={{ style: { overflowY: "visible" } }}
            TransitionComponent={Transition}
            aria-labelledby="Client Form"
            aria-describedby="Add new Client Desc"
        >
            <div className={classes.headerTitle} >
                <Typography className={classes.headerText} variant="h6">Add Expence</Typography>
                <IconButton onClick={onCloseHandler}>
                    <CancelIcon color="primary" fontSize="large" />
                </IconButton>
            </div>
            <Divider />
            <DialogContent>
                <form autocomplete="off" >
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <Autocomplete
                                blurOnSelect
                                disableClearable
                                options={data.expenses}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => { setFormField("project", value) }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Project"
                                        name="project"
                                        type="text"
                                        placeholder="Project"
                                        helperText={""}
                                        variant="filled"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Autocomplete
                                blurOnSelect
                                disableClearable
                                options={data.expenses}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => setFormField("project", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Project"
                                        name="project"
                                        type="text"
                                        placeholder="Project"
                                        helperText={""}
                                        variant="filled"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DatePicker
                                placeholderText="Start date"
                                name="start-date"
                                dateFormat="dd, MMMM yyyy"
                                selected={formik.values["start-date"]}
                                onChange={date => setFormField("start-date", date)}
                                customInput={<CustomInput />}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Amount"
                                name="amount"
                                type="number"
                                placeholder="Amount"
                                {...formik.getFieldProps("amount")}
                                helperText={""}
                                variant="filled"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Type"
                                name="type"
                                select
                                placeholder="Amount"
                                {...formik.getFieldProps("type")}
                                helperText={""}
                                variant="filled"
                                fullWidth
                            >
                                <MenuItem value="billable">billable</MenuItem>
                                <MenuItem value="Non-billable">Non-billable</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Status"
                                name="status"
                                select
                                placeholder="Status"
                                {...formik.getFieldProps("status")}
                                helperText={""}
                                variant="filled"
                                fullWidth
                            >
                                <MenuItem value="open">Open</MenuItem>
                                <MenuItem value="settled">Settled</MenuItem>
                            </TextField>
                        </Grid>

                        {
                            formik.values["status"] === "settled" ? (
                                <Grid item xs={6}>
                                    <DatePicker
                                        placeholderText="Settled Date"
                                        name="settled-date"
                                        dateFormat="dd, MMMM yyyy"
                                        selected={formik.values["settled-date"]}
                                        onChange={date => setFormField("settled-date", date)}
                                        customInput={<CustomInput />}
                                    />
                                </Grid>
                            ) : null
                        }

                    </Grid>
                </form>
            </DialogContent>
            <Divider />
            <DialogActions className={classes.dialogAction}>
                <Button size="small" variant="outlined" color="secondary">
                    CANCEL
                    </Button>
                <Button size="small" variant="contained" color="secondary">
                    SAVE
                    </Button>
            </DialogActions>
        </Dialog>
    );
}
const mapStateToProps = state => {
    return {
        data: state.expenses.data,
        expenseFormDialogIsOpen: state.expenses.expenseFormDialogIsOpen,
    };
};

export default connect(mapStateToProps, { toggleExpenseFormDialog })(ExpenceForm);